.order {
  &__content {
    &__wrapper {
      @include breakpoint(1690px) {
        padding-left: $sm;
        padding-right: $sm;
      }
    }
  }
  &__overview, &__address {
    &__wrapper {
      font-family: $font_sans_2;
      font-family: var(--font-alt);
      margin-bottom: $xl + $xxs; // 5.0rem + 1.0rem = 6.0rem
      @include grid-container();
    }
    &__row {
      @include grid-collapse();
      @extend .clearfix;
      margin-bottom: $sm;
    }
    &__column {
      @include grid-column(24);
      //margin-bottom: $sm;
      @include breakpoint($screen-xs) {
        @include grid-column(12);
      }
      @include breakpoint($screen-sm) {
        @include grid-column(8);
      }
      @include breakpoint($screen-md) {
        @include grid-column(4);
      }
    }
    &__title {
      font-weight: 400;
    }
    &__data {
      font-weight: 300;
    }
  }
  &__address {
    &__wrapper {
      width: 100%;
      @include breakpoint($screen-md) {
        width: 50%;
        float: left;
      }
    }
    &__heading {
      font-family: $font_sans_1;
      font-family: var(--font-main);
    }
  }
  &-table {
    font-weight: 300;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    &__actions {
      display: flex;
      align-items: flex-end;
      @include breakpoint(0 $screen-md) {
        padding-bottom: $xsm;
        padding-left: 0;
      }
      padding: $xs $xs 0;
    }
    &__column {
      @include breakpoint(0 $screen-md) {
        padding-bottom: $xsm;
      }
      @include breakpoint($screen-md) {
        padding-right: $xsm;
      }
      &-title {
        font-family: $font_sans_1;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    &__item {
      width: 100%;
      &:last-of-type {
        margin-bottom: $sm;
      }
      border-bottom: $border_style_global;
      @include clearfix();
      @include breakpoint($screen-md) {
        display: flex;
        padding: $lg 0 $lg 0;
        &__product {
          flex: 6;
        }
      }

      @include breakpoint(0 $screen-sm) {
        &__vat, &__price {
          width: 50%;
          text-align: right;
          float: left;
        }
      }
      @include breakpoint($screen-sm $screen-md) {
        display: inline-block;
        padding: 3.2rem 0 3.2rem 0;
        &__product {
          width: 100%;
        }
        &__delete {
          width: 100%;

          align-items: initial;
          text-align: center;
        }
        &__date, &__vat, &__price {
          margin-top: $xsm;
        }
        &__vat, &__price {
          @include grid-column(6);
          text-align: right;
          float: left;
          margin-bottom: 3.2rem;
        }
        &__vat {
          @include grid-push(12);
        }
        &__price {
          @include grid-push(0);
        }
      }
      &__product {
        &__title {
          font-size: $smd;
          @include breakpoint($screen-md) {
            font-size: $md;
          }
        }
        &__description {
          font-size: $xsm;
          @include breakpoint($screen-md) {
            font-size: $md;
          }
          margin-top: $md;
          .product__description-title {
            font-weight: 500;
          }
        }
      }
      &__delete, &__date, &__vat, &__price {
        display: flex;
        flex-direction: column;
        @include breakpoint($screen-md) {
          flex: 1;
        }
      }
      &__vat, &__price {
        font-weight: 500;
        font-size: $smd;
      }
      &__date {
        font-size: $xsm;
      }
      &__date, &__vat, &__price {
        @include breakpoint($screen-md) {
          justify-content: space-between;
          text-align: right;
          font-size: $smd;
        }
        &__title {
          font-size: $xsm;
          @include breakpoint($screen-md) {
            font-size: $md;
          }
        }
      }
      &__delete {
        font-size: $xs;
        @include breakpoint($screen-md) {
          align-items: flex-end;
          justify-content: flex-end;
        }
        .delete.btn {
          padding: $xs $md $xs $md;

        }
      }
      &__date {

      }
      &__vat {

      }
      &__price {

      }
    }
    @include breakpoint($screen-md) {
      &__item:not(:first-child) {
        .order-table__item__date__title, .order-table__item__vat__title, .order-table__item__price__title {
          display: none;
          padding-top: 0;
        }
        .order-table__item__date, .order-table__item__vat, .order-table__item__price {
          justify-content: flex-end;
        }
      }
    }
    &-summary {
      width: 100%;
      font-family: $font_sans_2;
      font-family: var(--font-alt);
      @include breakpoint($screen-xs) {
        width: 60vw;
      }
      @include breakpoint($screen-sm) {
        width: 40vw;
      }
      @include breakpoint($screen-md) {
        width: 35vw;
      }
      @include breakpoint($screen-lg) {
        width: 25vw;
      }
      #checkout-content & {
        width: 100%;
      }
    }
  }
  &-wrapper {
    max-width: $site-max-width;
    width: 100%;
    @include breakpoint($screen-md) {
      border: $border_style_global;
      border-radius: $border_radius_global;
      margin: 0 $xxs 0 $xxs;
      padding: $lg;
    }
    @include breakpoint(0 $screen-md) {
      padding: 2.3rem 3.6rem 2.3rem 3.6rem;
    }
  }
}