// This is the main navigation

@include keyframes(navFadeIn) {
  from {
    @include opacity(0);
  }
  to {
    @include opacity(1);
  }
}

header ul {
  a, > li > span {
    font-family: $font_sans_1;
    font-family: var(--font-main);
  }
}

.primary__nav__wrapper {
  max-width: $site-max-width;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.nav {
  min-height: 100%;
  position: absolute;
  //overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: $color_navigation_background;
  z-index: 10;
  top: 0;
  //bottom: 0;
  right: -100%;
  @include transition(transform);
  width: 100%;
  .nav--is-open & {
    @include breakpoint(max-width 991px) {
      padding-top: 40px;
      //text-align: center;
      transform: translateX(-100%);
    }
  }
  li {
    position: relative;
  }
  @include breakpoint($screen-md) {
    width: 100%;
    right: auto;
    position: relative;
    overflow: visible;
    border-left: none;
    font-size: 1.2rem;
    a {
      height: 100%;
      display: block;
      @include transition(all, 0.15s);
    }
  }
  &__wrapper {
    background-color: $color_navigation_background_mobile;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 7.2rem;
    width: 100%;
    .admin-bar & {
      top: 46px;
      @include breakpoint(782px) {
        height: auto;
        top: 32px;
      }
      @include breakpoint($screen-md) {
      }
    }
    max-width: $site-max-width;
    z-index: 999;

    @include breakpoint($screen-md) {
      width: auto;
      padding: 0 $sm;
      height: auto;
      background-color: $color_navigation_background;
      box-shadow: 0 0rem 0rem 0rem $color_shadow;
      @include transition(box-shadow);
      .scrolled & {
        box-shadow: 0 0 5.6rem -2.4rem;
      }
    }
  }
  .menu {
    list-style: none;
    margin: 0;
    @include breakpoint($screen-md) {
      //float: right;
      margin: 0;
      position: initial;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    li.menu-item {
      @include breakpoint(max-width $screen-md) {
        border-bottom: solid 1px $color_mobile_menu_navigation_link_border;
        .sub-menu-item {
          background-color: $color_megamenu_submenu_background;
          border-bottom: solid 1px darken($color_mobile_menu_navigation_link_border, 6%);
          .child-sub-menu-item {
            background-color: darken($color_megamenu_submenu_background, 12%);
            border-bottom: solid 1px darken($color_mobile_menu_navigation_link_border, 6%);
          }
        }
      }
    }
    > li {
      display: block;
      //border-bottom: solid 1px $color_mobile_menu_navigation_link_border;
      @include breakpoint($screen-md) {
        float: left;
        border-bottom: none;
      }
      a, > span {
        font-size: $xsm;
        text-transform: uppercase;
        padding: $xxs $xsm;
        display: block;
        color: $color_navigation_link;
        text-decoration: none;
        text-align: left;
        font-weight: 400;
        @include breakpoint($screen-md) {
          padding: $md 1.2rem;
          display: block;
        }
        @include breakpoint($screen-lg) {
          padding: $md $smd;
        }
        @include breakpoint(1200px) {
          font-size: 1.6rem;
        }
      }

      &:hover, &:focus {
        color: $color_navigation_link;
        background-color: $color_navigation_link_hover_background;
        @include breakpoint($screen-md) {
          color: $color_navigation_link;
        }
      }
    }
  }
}

// MENU ITEM WITH CHILDREN & DROPDOWN STYLES
//the top level parent <li>
.menu-item-has-children {
  &.nav-main-item {
    //&:hover {
    //  > .sub-menu {
    //    border: $border_style_global;
    //    border-top: none;
    //    li:hover, li:focus {
    //      background-color: $_grey_vv_light;
    //    }
    //  }
    //}
  }
  @include breakpoint($screen-md) {
    &.nav-main-item:not(.mega-menu) {
      &:hover {
        > .sub-menu {
          border: $border_style_global;
          border-top: none;
          li:hover {
            background-color: $_grey_vv_light;
          }
        }
      }
    }
    &.nav-main-item.mega-menu {
      &:hover {
        > .sub-menu {
          li:hover > a > span {
            color: $color_brand_primary;
          }
        }
      }
    }
  }
  // The sub level <ul> inside the top level parent <li>
  //position: relative;
  .menu-item-chevron {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    top: 1.2rem;
    right: 2rem;
    pointer-events: none;
    border: 2px solid;
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
    transition: transform 0.15s ease-in-out;
  }
  .menu-heading {
    .menu-item-chevron {
      top: 1.5rem;
    }
  }
  .nav-main-link {
    @include breakpoint(max-width $screen-md){
      position: relative;
      padding-right: 6rem;
      width: 100%;
      display: flex;
    }
  }
  a {
    .nav-main-link {
      @include breakpoint(max-width $screen-md) {
        pointer-events: none;
        .menu-item-chevron {
          right: 0.5rem;
          top: 0;
        }
      }
    }
  }
  .sub-menu {
    min-width: 30rem;
    list-style: none;
    display: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: all 0.15s ease-in-out;
    ul {
      background-color: $color_navigation_background;
    }
    // Javascript added class rules
    &-open {
      display: block;
      height: auto;
    }
    @include breakpoint($screen-md) {
      position: absolute;
      height: auto;
      opacity: 1;
    }
    padding: 0;

    // Sub menu of a sub menu

    .sub-menu {
      display: none;
      left: 100%;
      top: 0.1rem;
      &.child-sub-menu-open {
        display: block;
      }
      li {
        width: 100%;
        a {
          color: $color_submenu_navigation_link;
        }
        &:hover, &:focus {

          background-color: $color_submenu_navigation_link_hover_background;
          a {
            color: $color_submenu_navigation_link;
          }
        }
      }
    }
    // all direct child <li> of sub <ul>
    > li {
      width: 100%;
      // all <a> within a direct child <li>
      a {
        border-radius: 0;
        color: $color_submenu_navigation_link;
        padding: $sm $md;
        font-size: 1.2rem;
        //border-bottom: solid 1px rgba($color_submenu_navigation_link_border, 0);
        @include breakpoint($screen-md) {
          // on desktop we give the <a> a bit of room on the right
          padding-right: $xs !important;
          border-bottom: none;
        }
      }

      &:hover, &:focus {
        a {
          color: $color_submenu_navigation_link_hover;
          border-color: rgba($color_submenu_navigation_link_border, 1);
        }
      }
    }
  }
  //
  // HOVER STATES
  //
  // hover rules for the top level/parent <li>
  &:hover {
    > .sub-menu {
      @media screen and (min-width: $screen-md) {
        display: block;
        flex-direction: row;
        //border: $border_style_global;
        //border-top: none;
      }
    }
  }
  &__logged-in {
    .sub-menu {
      flex-direction: column;
    }
  }
}

.is--open {
  > .menu-item-chevron {
    transform: rotate(135deg);
  }
}

.column--open {
  display: block;
  background-color: darken($color_navigation_background, 15%)!important;
}

.column--close {
  display: none;
}

.menu {
  &-trigger {
    &__wrapper {
      @include breakpoint($screen-md) {
        display: none;
      }
      user-select: none;
      cursor: pointer;
      font-size: 1.6rem;
      text-transform: uppercase;
      padding: 1.4rem 4.2rem 1.4rem 1.4rem;
      font-weight: normal;
      right: 0;
      top: 0;
      &:hover, &:focus {
        .menu-trigger__text {
          @include transition();
          color: $color_mobile_navigation_control_hover;
        }
        color: $color_mobile_navigation_control_hover;
        & .menu-trigger:before, & .menu-trigger__icon:before, & .menu-trigger__icon:after {
          background-color: $color_mobile_navigation_control_hover!important;
        }
      }
    }
    &:before, & .menu-trigger__icon:before, & .menu-trigger__icon:after {
      background-color: $color_mobile_navigation_control;
      content: '';
      position: absolute;
      right: 1.6rem;
      z-index: 0;
      width: 1.6rem;
      height: 2px;
      margin: 0 auto;
      @include transition();
    }
    &:before {
      top: 50%;
      transform: translateY(-100%);
    }
    z-index: 9994;
    .menu-trigger__icon {
      &:before {
        top: calc(50% - 7px);
      }
      &:after {
        bottom: calc(50% - 5px);
      }
    }
    .nav--is-open & {
      &:before {
        opacity: 0;
      }
      .menu-trigger__icon {
        &:before {
          transform: rotate(45deg) translateY(-50%);
        }
        &:after {
          transform: rotate(-45deg) translateY(-50%);
          right: 15px;
        }
        &:before, &:after {
          top: 50%;
          background-color: $color_mobile_navigation_control_hover;
        }
      }
    }
    &__text {
      .nav--is-open & {
        color: $color_mobile_navigation_control_hover!important;
      }
    }
  }
}


// MEGA MENU

$subMenuPadding-Top-Bottom: $lg;

.mega-menu {
  &-parent {
    @include breakpoint($screen-md) {
      position: initial !important;
    }
  }
  .child-sub-menu {
    .child-sub-menu-open {
      display: block;
    }
  }
  > .sub-menu--wrapper {
    //display: block; // uncomment for dev
    will-change: height;
    transition: height 0.15s ease-in-out;
    right: 0;
    left: 0;
    background-color: $color_megamenu_background;
    @include breakpoint($screen-md) {
      //display: flex; // uncomment for dev
      padding: $subMenuPadding-Top-Bottom $xs;
      //min-height: calc(100vh - 154px); // uncomment for fit screen
    }
    .sub-menu-list {
      background-color: $color_megamenu_submenu_background;
      position: relative;
      @include breakpoint($screen-md) {
        @include grid-column(4, $gutterless-grid);
        padding: 0 $xs;
        border-right: solid 1px $color_megamenu_submenu_border;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          border-right: none;
          //padding-right: 0;
        }
      }
      .menu-heading {
        text-transform: uppercase;
        text-align: left;
        padding: $xs $xsm;
        font-size: $xsm;
        @include breakpoint($screen-md) {
          padding: $xs;
          font-weight: 900;
          font-size: $xsm;
        }
        &:hover, &:focus {
          background-color: transparent;
        }
      }
      > li {
        @include breakpoint($screen-md) {
          position: initial;
        }
        &:hover, &:focus, &.sub-menu-open {
          @include breakpoint($screen-md) {
            background-color: $color_megamenu_submenu_link_background_hover;
          }
        }

        @include breakpoint($screen-md) {
          a {
            padding: $xs;
            text-transform: initial;
            font-size: $xsm;
            font-family: $font_sans_2;
            font-family: var(--font-alt);
            font-weight: 300;
          }
        }
        &.menu-item-has-children, &.link-all--items {
          > a {
            position: relative;
            padding-right: $md;
            &:before, &:after {
              @include breakpoint($screen-md) {
                content: '';
                display: block;
                width: 0.1rem;
                height: $xxxs;
                background-color: $color_navigation_link;
                position: absolute;
                right: $xs;
              }
            }
            &:before {
              transform: rotate(-45deg);
              bottom: calc(50% - 3px);
            }
            &:after {
              transform: rotate(45deg);
              top: calc(50% + 0px);
            }
          }
        }
      }
      @include breakpoint(max-width $screen-md) {
        > .sub-menu-item {
          //display: none;
          &.menu-heading {
            display: block;
          }
        }
      }
    }
    // THIRD LEVEL SUB MENU
    & .menu-item-has-children {
      .sub-menu {
        //display: block;

        z-index: 9;
        position: static;
        right: 0;
        left: calc(100% - #{$xs});
        top: -$subMenuPadding-Top-Bottom;
        bottom: -$subMenuPadding-Top-Bottom;
        background-color: $color_navigation_background;
      }
    }
  }

  @include breakpoint($screen-md) {
    &:hover {
      > .sub-menu {
        display: flex !important;
      }
    }
    li.link-all--items {
      span {
        font-weight: 900;
        color: $color_brand_primary;
      }
    }
  }
}

// Pagination


a.page-numbers, span.page-numbers, .dots {
  width: 4.8rem;
  height: 4.8rem;
  transition: all 0.15s ease-in-out;
  padding: 0;
  line-height: 3.5;
  font-size: 1.4rem;
  font-weight: bold;
  color: $color_pagination_item;
  background-color: $color_pagination_item_background;
  display: inline-block;
  text-align: center;
  &:hover, &:focus {
    background-color: $color_pagination_item_hover_background !important;
    color: $color_pagination_item_hover !important;
    transform: translateY(-$xs);
  }
  &.active, &.current {
    background-color: $color_pagination_item_active_background;
    color: $color_pagination_item_active;
  }
}



// SUPERNAV

.supernav {
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    @include breakpoint($screen-md) {
      display: block;
    }
    background: $color_supernav_background;
    border-bottom: $border_style_global;
  }
  &__container {
    font-size: 0;
    //@include grid-container();
    margin: 0 auto;
    max-width: $site-max-width;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__links {
    &--left {
      width: 60%;
      max-width: 25rem;
      min-width: 20rem;
      @include breakpoint($screen-md) {
        width: initial;
        margin-left: 2rem;
      }
    }
    &--right {
      display: none;
      @include breakpoint($screen-md) {
        display: flex;
      }
      width: 100%;
      justify-content: flex-end;

      align-items: center;
      .account--link {

        &--login {

          @include breakpoint($screen-md) {
            margin-right: 4rem;
          }
        }
        &--logged-in {
          position: relative;
          .welcome{
            margin-right: 3.5rem;
          }
          .sub-menu {
            display: none;
            position: absolute;
            z-index: 998;
            background: white;
            width: 100%;
            border: $border_style_global;
            li a {
              display: block;
              padding: $sm;
              &:hover, &:focus {
                background-color: $color_account_navigation_item_background_hover;
              }
            }
          }
          .account--link--account {
            cursor: default;
            margin-right: 4.5rem;
            &:after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              height: 1px;
              bottom: -1px;
              z-index: 999;
              background-color: white;
              opacity: 0;
              //@include transition(opacity);
            }
          }
          &:hover {
            .account--link--account {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              position: relative;
              background-color: white;
              cursor: default;
              color: #000;
              &:after {
                opacity: 1;
              }
            }
            .sub-menu {
              display: block;
              top: calc(100% - 1px);
            }
          }
        }
      }
    }
    &__mobile {
      display: block;

      @include breakpoint($screen-md) {
        display: none!important;
      }
    }
  }
  &--link {
    @include btn-theme($color_supernav_link_background, $color_supernav_link_text, $color_supernav_link_hover_background, $color_supernav_link_hover_text);
    //padding: $sm $smd;
    display: block;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  &--subtle-promo-link {
    text-decoration: underline;
  }
}

$counterSize: 3rem;

.basket {
  &__link {
    &__wrapper {
      display: flex;
      align-items: center;
      margin: 0 $sm;
      a {

        position: relative;
      }
    }
  }
  &__counter {
    &__wrapper {
      position: absolute;
      width: $counterSize;
      height: $counterSize;
      border-radius: 50%;
      display: flex;
      align-items: center;
      background-color: $color_brand_primary;
      top: -8px;
      left: -12px;
    }
    &__fig {
      position: relative;
      display: block;
      margin: auto;
      font-size: $xsm;
      font-weight: 900;
      color: $_white;
    }
  }
}