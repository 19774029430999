#checkout-content {
  font-family: $font_sans_2;
  font-family: var(--font-alt);
}

@include breakpoint($screen-md) {

  .order {
    &__wrapper {
      width: 100%;
    }
  }

  .order-table-summary-wrapper {
    background-color: $_white;
    .checkout-content__wrapper & {
      @include grid-column(6);
      @include grid-push(2);
    }
  }
}

#payment-spinner {
  display: none;
  position: fixed;
  z-index: 999;
  overflow: initial;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: $xl;
  height: $xl;
  background-image: url(/style/images/spinner.gif);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $xl $xl;
}

/* Transparent Overlay */
#payment-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

.accordion {
  &__container {
    @include breakpoint($screen-md) {
      @include grid-column(16);
    }
  }
  &__wrapper {
    margin-bottom: $smd;
    opacity: 0.45;
  }
  &--active {
    opacity: 1;
  }
  &__content {
    .order__section{
      display: none;
    }
    .accordion--active & {
      padding: $sm;
      border: $border_style_global;
      border-bottom-left-radius: $border_radius_global;
      border-bottom-right-radius: $border_radius_global;
      .order__section{
        display: block;
      }
    }
    .addresses{
      &__wrapper{
        display: flex;
      }
    }
  }
  &__header {
    padding: $sm;
    width: 100%;
    background-color: $color_brand_secondary;
    color: $color_text_secondary;
    @include breakpoint($screen-md) {
      border-top-left-radius: $border_radius_global;
      border-top-right-radius: $border_radius_global;
      border-bottom-left-radius: $border_radius_global;
      border-bottom-right-radius: $border_radius_global;
    }
    .accordion--active & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    display: flex;
    align-items: center;
    @include breakpoint($screen-md) {
      font-size: 2.8rem;
    }
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    &__step {
      display: flex;
      width: $md;
      height: $md;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 1.6rem;
      margin-right: 2rem;
      color: black;
      border-radius: 50%;
    }
  }
  &__traverse__buttons {
    .btn {
      width: 100%;
      display: block;
      margin-top: $sm;
    }
    @include breakpoint($screen-sm) {
      .form-group {
        display: flex;
        justify-content: flex-end;
        .btn {
          margin-top: initial;
          width: auto;
          display: inline-block;
          &:last-of-type {
            margin-left: $sm;
          }
        }
      }
    }
  }
}

.card-details {
  padding: 4px 12px;
  background: $_grey_vv_light;
  height: $xl;
  display: flex;
  flex-flow: column;
  justify-content: center;
}