$grid-columns: 24;

$sizes: (
  0:      0,
  $xxs:   xxs,
  $xs:    xs,
  $xsm:   xsm,
  $sm:    sm,
  $smd:   smd,
  $md:    md,
  $mdl:   mdl,
  $lg:    lg,
  $xl:    xl,
  $xxl:   xxl,
  $xxxl:  xxxl
);

$neat-grid: (
  columns: $grid-columns,
  gutter: 2.4rem,
  direction: ltr,
);

$grid-8: (
  columns: 8,
  gutter: 2.4rem,
  direction: ltr,
);

$gutterless-grid: (
  gutter: 0,
);

@for $i from 1 through $grid-columns {
  .col--#{$i} {
    @include grid-column($i);
  }
  .col--push--#{$i} {
    @include grid-push($i);
  }
}

%clearfix {
  @include clearfix();
}

.grid-row {
  @include grid-collapse();
}

%kamblock {
  max-width: $site-max-width;
  display: block;
  width: 100%;
  margin: 0 auto;
  @include clearfix();
  &-flex {
    @extend %kamblock;
    display: flex;
    height: inherit;
    //&:last-of-type > div {
    //  margin-bottom: 0;
    //}
  }
}

.site__wrapper {
  display: block;
  height: inherit;
}

.no__hero__spacer {
  height: 14.7rem;
  @include breakpoint($screen-md) {
    height: 19.7rem;
  }
}

.contacts__wrapper {
  @include grid-column(12);
  @include breakpoint($screen-md) {
    @include grid-column(5);
  }
}

.section {
  padding-right: $md;
  padding-left: $md;
  padding-top: $lg;
  padding-bottom: $lg;
  @include breakpoint($screen-md) {
    padding-right: $sm;
    padding-left: $sm;
  }
  @include clearfix();
  &--padx2 {
    @extend .section;
    @include breakpoint($screen-md) {
      padding-top: $xxl;
      padding-bottom: $xxl;
      + .section--padx2 {
        padding-top: 0;
      }
    }
  }
  &__contact {
    padding-right: 0;
    padding-left: 0;
  }
}