$themeName: Stormpress;
$themeURI: "stormpress.co.uk";
$themeVersion: "1.0";


// MQ breakpoints

// Define the breakpoints at which your layout will change, adapting to different screen sizes.
$screen-xs: 480px !default;
$screen-xsm: 580px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

// Sizes relative to 10px set at body level.
$xxxs: 0.8rem; //8px
$xxs: 1.0rem; //10px
$xs: 1.2rem; //12px
$xsm: 1.6rem; //16px
$sm: 1.8rem; //18px
$smd: 2.0rem; //20px
$md: 2.4rem; //24px
$mdl: 2.6rem; //26px
$lg: 4.0rem; //40px
$xl: 5.0rem; //50px
$xxl: 8.0rem; //80px
$xxxl: 10rem; //100px

// Navbar heights are used when we have a fixed position header and we need to give the site padding/margin at the top in order to stop content starting at the top of the viewport.
$main_nav_height: 13.6rem;
$main_nav_height_sm: 7.1rem;

// Site Container and Max Widths
$site-max-width: 1644px;

$site-nav-height: 9.2rem;


