.team {
  &__member {
    //padding: $xsm;
    text-align: center;
    @include grid-column(24);
    @include breakpoint($screen-xsm) {
      @include grid-column(12);
    }
    @include breakpoint($screen-sm) {
      @include grid-column(8);
    }
    @include breakpoint($screen-md) {
      @include grid-column(6);
    }
    @include breakpoint($screen-lg) {
      @include grid-column(4);
    }
    &__image {
      margin-bottom: $xsm;
    }
    &__name {
      text-transform: uppercase;
      margin-bottom: 0.4rem;
      font-weight: 500;
      font-size: $md;
    }
    &__title {
      font-family: $font_sans_2;
      font-family: var(--font-alt);
      margin-bottom: $xsm;
      font-size: $smd;
    }
  }
}