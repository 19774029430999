@use "sass:math";
input {
  -webkit-appearance: none;
  border: none;
  font-size: 1.6rem;
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  padding: 2rem 2.4rem;
  width: 100%;
  height: 5.4rem;
  border-radius: $border_radius_global;
  border-radius: $border_radius_global;
  border-radius: $border_radius_global;
}

input[type="radio"]{
  width: initial;
  -webkit-appearance: radio;
  height: initial;
}




.form {
  &-group {
    @include grid-column(24);
    label {
      font-family: $font_sans_2;
      font-family: var(--font-alt);
    }
    &__wrapper {
      margin-bottom: $sm;
      @include grid-container();
      label {
        margin-bottom: $xxs;
        display: inline-block;
        font-weight: 300;
        font-family: $font_sans_2;
        font-family: var(--font-alt);
      }
    }
    &--half {
      @include breakpoint($screen-md) {
        @include grid-column(12);
      }
    }
    .legend {
      margin-bottom: $sm;
      display: inline-block;
    }
    &__radio[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      position: absolute !important;
      z-index: -1000;
      left: -62.5em;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
    &__radio-label {
      background-color: transparent;
      padding-left: $smd !important;
      text-indent: 0!important;
      margin-bottom: $xsm;
      height: 2rem;
      display: block !important;
      line-height: 2rem;
      background-repeat: no-repeat;
      background-position: 0 0;
      cursor: pointer;
      position: relative;
      &:before, &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        transition: all 0.2s ease;
      }

      $radioCircleSize: $xs;

      &:before {
        background-color: transparent;
        width: $radioCircleSize;
        height: $radioCircleSize;
        left: 5px;
        top: 5px;
        border: solid 1px $_grey_dark;
      }
      &:after {
        background-color: transparent;
        width: math.div($radioCircleSize, 2);
        height: math.div($radioCircleSize, 2);
        left: $xxxs;
        top: $xxxs;
        transform: scale(0);
      }
      &.is--checked {
        &:after {
          background-color: $_grey_dark;
          transform: scale(1);
        }
      }
    }
    .address-select__wrapper {
      position: relative;
      margin-bottom: 1.6rem;
      display: none;
      align-items: center;
      width: calc(100% + #{$sm});
      &:after {
        content:'';
        @include chevron(down);
        top: -10px;
        position: relative;
        right: $md;
      }
      select {
        border: $border_style_global;
        font-weight: 300;
        font-size: $sm;
        font-family: inherit;
        width: 100%;
        appearance: none;
        border-radius: $border_radius_global;
        background-color: $_grey_vv_light;;
      }
    }
    .address-select__wrapper.is--displayed {
      display: flex;
    }
  }
}

.messages {
  margin: $smd auto;
  padding: $smd;
  color: $color_error;
  border: $border_style_global;
  border-radius: $border_radius_global;
}

#txtEmail-error {
  display: block;
  margin: $smd auto;
  padding: $smd;
  border: $border_style_global;
  border-radius: $border_radius_global;
  color: $color_error;
}



.form-control {
  height: $xl;
  font-size: $smd;
  background-color: $_grey_vv_light;
  border: $border_style_global;
  width: 100%;
  margin-bottom: $xsm;
}

.validation-failed{
  border-color: $color_error;
  border-width: 0.2rem;
}

textarea.form-control {
  height: auto;
  border-radius: $border_radius_global;
  font-size: $smd;
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  padding: 2rem 2.4rem;
}

.newsletter {
  &__input {
    &__wrapper {
      position: relative;
      padding: 0 2rem;
      max-width: 53rem;
      height: 5.4rem;
      margin: 2rem auto 3rem;
      float: none;
      @include breakpoint($screen-md) {
        margin: 2rem auto 6rem;
      }
      label {
        opacity: 0;
      }
    }
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    &--submit {
      left: initial;
      width: 4rem;
      font-size: 0;
      background-color: rgba($_grey_v_light, 0);
      cursor: pointer;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transition: background-color 0.25s ease-in-out;
      &:hover {
        background: rgba($_grey_v_light, 0.15);
      }
    }
  }
}

.login__form {
  input {
    border: solid 1px $_grey_dark;
    border-radius: $border_radius_global;
    background-color: $_grey_vv_light;
  }
}

.g-recaptcha-bubble-arrow
{
  display: none;
}

.g-recaptcha-bubble-arrow + div
{
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}