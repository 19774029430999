
.pop-up-mask {
  background-color: #000;
  opacity: 0.4;
  z-index: 11; }

.pop-up-window {
  z-index: 1000;
  display: none;
  overflow: initial;
  margin: auto;
  top: 50%;
  left: 50%;
  background-color: $color_body_background;

  .close-button {
    position: absolute;
    display: block;
    z-index: 2;
    right: $xxs;
    top: $xxs;
    width: $xsm;
    height: $xsm;
    background: url(/style/images/popup-window-close.png) no-repeat 0 0 transparent;
  }

  .circle.ok-button {
      position: relative;
      left: 65%;
      bottom: $xxs;
      padding: 1.2rem 3rem;
  }

  .content {
    padding: 10px 13px;
    height: 100%;
    overflow-y: auto;

    h5 {
      font-size: 16px;
      font-weight: bold;
    }
  }


  .help .content span.more {
    display: none;
  }

  .messages {
    h3 {
      margin-top: 10px;
    }

    ul {
      padding-left: 0px;
      list-style: none;
    }
  }
}
.upload-artwork {
  .circle.confirm-button {
    margin-top: 25px;
    .state-text-confirmed {
      display: none;
    }
  }
  .circle.confirm-button.state-confirmed {
    margin-top: 25px;
    .state-text-confirmed {
      display: inline;
    }
    .state-text-default{
      display: none;
    }
  }
  .content {
    overflow: hidden;
    padding-bottom: 20px;

    .info-section {
      display: flex;
      &__description {
        h4 {
          float: left;
          margin-bottom: 5px;
          font-weight: bold;
        }
        p {
          float: left;
        }
        .question {
          padding: 0.4rem 0.8rem;
          box-shadow: none;
          bottom: 0.35rem;
        }

      }
      &__btn{

      }
      .browse-for-file {
        position: relative;
        margin-left: 0.5rem;
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          opacity: 0.01;
          height: 100%;
        }
      }
    }
  }
}

.editorModal{
  background:#FFF;
  border:1px solid #AAAAAA;
  padding:0.2rem;
  .header{
    height:4.0rem;
    margin:0 0.2rem 0.2rem 0;
    .title{
      float:left;
      font-weight:bold;
      font-size: 1.9rem;
      padding:0.6rem 1.5rem;
      margin: 0;
    }
    .close{
      background: url(/style/images/popup-window-close.png) no-repeat;
      margin: 0.7rem 0.7rem 0 0;
      float: right;
      width:2.1rem;
      height:2.0rem;
    }
  }
  .orderBar{
    height:5rem;
    .filenameLabel, .categoryLabel{
      margin: 1.9rem 0 1rem 1rem;
      font-size: 1.4rem;
      float: left;
    }
    .filenameInput, select{
      color: #000;
      border: none;
      box-shadow: 0 0 0.6rem 0.4rem #dbdbdb;
      float:left;
      border:none;
      outline:none;
      padding: 0.6rem 1.2rem;
      width:31rem;
      height:3.4rem;
      color:#000;
      border-radius:0.4rem;
      margin:0 0 2.0rem 0;
      margin-bottom: 1.5rem;
    }
    input[type=text], select {
      margin-top: 0.9rem;
      margin-left: 1.2rem;
    }
    .btn{
      float: right;
      margin:0.5rem 0.5rem;
    }
  }
}

