@charset "UTF-8";
/// Creates collapsed grid object that consumes the gutters of its container,
/// for use in nested layouts.
///
/// @group features
///
/// @name Grid collapse
///
/// @argument {map} $grid [$neat-grid]
///   The grid to be used to generate the collapsed container.
///   By default, the global `$neat-grid` will be used.
///
/// @example scss
///   .element {
///     @include grid-collapse;
///   }
///
/// @example css
///   .element {
///     margin-left: -20px;
///     margin-right: -20px;
///     width: calc(100% + 40px);
///   }

@mixin grid-collapse($grid: $neat-grid) {
  $_grid-gutter: _retrieve-neat-setting($grid, gutter);

  @if unit($_grid-gutter) == "%" {
    @warn "`grid-collapse` is not compatible with percentage based gutters.";
  }

  margin-#{_neat-float-direction($grid)}: -($_grid-gutter);
  margin-#{_neat-opposite-direction($grid)}: -($_grid-gutter);
  width: calc(100% + #{($_grid-gutter * 2)});
}
