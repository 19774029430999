.grid {
  &__wrapper {
    @extend %kamblock;
  }
  &__blocks {
    @include grid-container();
  }
  &__title {
    text-align: center;
    padding-bottom: $lg;
    margin-bottom: 0;
    @include breakpoint($screen-md) {
      padding-bottom: $xxl;
    }
  }
  &__block {
    text-align: center;
    padding-bottom: $smd;
    @include grid-column(24, $gutterless-grid);
    @include breakpoint($screen-xsm) {
      @include grid-column(12);
    }
    @include breakpoint($screen-md) {
      @include grid-column(8);
    }
    &:last-child {
      @include clearfix;
    }
    &__img {
      margin-bottom: $mdl;
    }
    &__heading {
      font-size: $sm;
      margin-bottom: $xxs;
      @include breakpoint($screen-md) {
        font-size: $md;
        margin-bottom: $xsm;
      }
    }
    &__txt p {
      font-size: $xs;
      margin-bottom: $xxs;
      @include breakpoint($screen-sm) {
        font-size: 2rem;
        margin-bottom: $xsm;
      }
    }
    &__link {
      font-weight: 600;
      color: $color_brand_primary;
      font-family: $font_sans_2;
      font-family: var(--font-alt);
      margin-bottom: $sm;
      display: block;
    }
    &__btn {
      &-wrapper {
        text-align: center;
        padding: $sm 0;
      }
    }
  }
}