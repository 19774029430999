#basket-content, .checkout-basket__content {
  display: flex;
  justify-content: center;
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  font-weight: 300;
  @include breakpoint($screen-md) {
    margin: $xxl 0 $xxl 0;
  }
  .order-wrapper {

    .order-table {

    }

  }
}

.checkout-basket__content {
  .order-wrapper {
    border: none;
    padding: 0;
    & .order-table {
      &__item {
        &__product__description {
          @include breakpoint($screen-md) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.summary__container {
  width: auto;
  margin: auto;
  right: 3rem;
  @include breakpoint($screen-md) {
    position: fixed;
    @include grid-column(8);
  }
}

.order-table-summary {
  &-wrapper {
    display: flex;
    #checkout-content & {
      padding: $sm;
      border: $border_style_global;
      border-radius: $border_radius_global;
    }
    @include breakpoint($screen-md) {
      justify-content: flex-end;
    }
    @include breakpoint(0 $screen-md) {
      justify-content: center;
    }
    .form-group {
      width: 100%;
      float: none;
      margin: 0 auto;
    }
    .btn {
      width: 100%;
    }
  }
  #basket-content & {
    padding: $sm;
    border: $border_style_global;
    border-radius: $border_radius_global;
  }
  @include breakpoint($screen-md) {

  }
  @include breakpoint(0 $screen-md) {

  }
  &__totals {
    &__sub, &__delivery, &__vat, &__total, &__coupon {
      display: flex;
      justify-content: space-between;
      font-size: $smd;
      line-height: 2;
    }
    &__coupon {
      .btn.btn--secondary {
        width: initial;
        padding: 0.4rem 0.4rem;
        line-height: 1.4rem;
      }
    }
    &__sub, &__total {
      color: $color_brand_primary;
      font-weight: 500;
    }
  }

  &__proceed {
    font-size: $xsm;
    .btn {
      padding: $md $lg $md $lg;
      text-align: center;
      width: 100%;
    }
  }
}

.couponWrapper {
  margin: $xsm 0 $mdl 0;
  overflow: auto;

  @include breakpoint($screen-md) {
    @include grid-collapse();
  }
  .form-group {
    .coupon-label {
      display: block;
      margin-bottom: $xxs;
      @include breakpoint($screen-md) {
        @include grid-column(24);
      }
    }

    @include breakpoint($screen-md) {
      @include grid-container();
    }

    .form-control {
      @include breakpoint($screen-md) {
        @include grid-column(14);
        margin-bottom: 0;
      }
    }

    .btn {
      height: $xl;
      width: 100%;
      @include breakpoint($screen-md) {
        @include grid-column(10);
      }
    }
  }
}