.login {
  &__content {
    &__wrapper {
      text-align: center;
    }
  }
  &__form {
    &__wrapper {
      max-width: 666px;
      margin: 0 auto;
      text-align: left;
    }
  }
}

.forgot-pass {
  margin-bottom: 1rem;
  display: inline-block;
  color: $color_brand_primary;
  text-decoration: underline;
}