#product-wrapper{
  label {
    font-size: $smd;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    font-weight: 300;
  }
  li {
    font-size: $smd;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    font-weight: 300;
  }
  #content-area{
    .view-specification{
      display: flex;
      justify-content: space-evenly;
      margin: 7.5rem 0;
      @include breakpoint(0 $screen-md) {
        flex-direction: column-reverse;
      }
      #leftCol{
        width: 40%;
        @include breakpoint($screen-md $screen-lg) {
          width: 46%;
        }
        @include breakpoint(0 $screen-md) {
          width: 90%;
          margin: 0 auto;
        }
        .product-content{
          &--title {
            display: flex;
            position: relative;
            right: 6rem;

            align-items: center;

            margin-bottom: 1rem;
            h1, h2, h3, h4 {
              margin-bottom: 0;
            }
            @include breakpoint(0 $screen-md) {
              flex-direction: column;
              position: initial;
            }
            img{
              width: $lg;
              height: $lg;
              margin-right: $smd;
              @include breakpoint(0 $screen-md) {
                margin-bottom: $smd;
              }
            }
            h2 {
              margin-bottom: $smd;
            }
          }
          &--description {
            & + .product-content--title {
              margin-top: 4rem;
            }
            p{
              a{
                padding: 0;
                color: $color_brand_primary;
                font-weight: 700;
              }
            }
            ul{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-bottom: $mdl;
              li{
                flex-basis: 50%;
                margin-bottom: 0.6rem;
                list-style-type: disc;
                list-style-position: inside;
                @include breakpoint($screen-md $screen-lg) {
                  flex-basis: 100%;
                }
                @include breakpoint($screen-sm $screen-md) {
                  flex-basis: 50%;
                }
                @include breakpoint(0 $screen-sm) {
                  flex-basis: 100%;
                }
              }
            }
          }
          &--image-list {
            display: flex;
            justify-content: space-between;

            margin-bottom: 2.4rem;
            @include breakpoint(0 $screen-xs) {
              flex-direction: column;
            }
            ul{
              li:first-of-type{
                margin-bottom: $xxs;
              }
              li{
                margin-bottom: $mdl;
                display: flex;
                align-items: center;
                img{
                  width: 40px;
                  height: 40px;
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
      #rightCol {
        width: 30%;
        @include breakpoint($screen-md $screen-lg) {
          width: 40%;
        }
        @include breakpoint(0 $screen-md) {
          width: 90%;
          margin: 0 auto $lg auto;
        }
      }
    }
  }
}