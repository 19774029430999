$banner_image_size: 7rem;
$banner_image_size_desktop: 9.6rem;

.banner {
  &__wrapper {
    width: 100%;
    background-color: $color_banner_background;
  }
  //@include grid-column(24, $gutterless-grid);
  @extend %kamblock;
  display: flex;
  @include breakpoint(0 $screen-md) {
    flex-wrap: wrap;
  }
  &__item {
    margin: auto;
    flex-direction: column;
    text-align: center;
    width: 50%;
    //max-width: 12.4rem;
    padding: $xsm;
    @include breakpoint($screen-md) {
      width: auto;
      //max-width: 17.4rem;
      padding: $smd;
    }
  }
  &__image {
    margin: 0 auto $sm;
    display: flex;
    width: $banner_image_size;
    height: $banner_image_size;
    @include breakpoint($screen-md) {
      width: $banner_image_size_desktop;
      height: $banner_image_size_desktop;
    }
  }
  &__title {
    color: $color_text_secondary;
    font-size: $xs;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    font-weight: 300;
    @include breakpoint($screen-sm) {
      font-size: $sm;
    }
  }
}