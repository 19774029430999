// FLEX APPROACH

.list-section {
  &--flex {
    @extend %kamblock-flex;
    flex-flow: column;
    @include breakpoint($screen-sm) {
      flex-flow: wrap;
      justify-content: space-between;
    }
  }
  &__list {
    margin-bottom: $lg;
    padding: 0 $xxs;
    width: 100%;

    @include breakpoint($screen-sm) {
      width: 33.333%;
      float: left;
    }

    @include breakpoint($screen-md) {
      width: 25%;
      float: left;
    }
  }
  &__item {
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: $smd;
    margin-bottom: 0.25em;
    @include breakpoint($screen-md) {
      font-size: 2.6rem;
    }
    & a {
      &:hover {
        color: $color_brand_primary;
      }
    }
    // Cap Letter Title
    &:first-of-type {
      font-size: 2.4rem;
      @include breakpoint($screen-md) {
        font-size: $lg;
      }
      margin-bottom: 0.5em;
      color: $color_brand_primary;
      font-family: $font_sans_1;
      font-weight: 500;
    }
  }
}