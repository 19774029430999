@use "sass:math";
//////////////////////////
/*

Utility classes do exactly what they say on the tin — they're
especially helpful for rapid prototyping

*/
////////////////////////

.clearfix {
  &:before, &:after {
    display: table;
    width: 100%;
    clear: both;
    content: '';
  }
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.backgroundblendmode {
  .blend {
    &--overlay {
      mix-blend-mode: overlay;
    }
    &--multiply {
      mix-blend-mode: multiply;
    }
    &--screen {
      mix-blend-mode: screen;
    }
    &--difference {
      mix-blend-mode: difference;
    }
    &--colorburn {
      mix-blend-mode: color-burn;
    }
  }
}

.no-backgroundblendmode {
  .blend {
    &--overlay,
    &--multiply,
    &--screen,
    &--difference,
    &--colorburn {
      opacity: 0.5;
    }
  }
}

// center with margin – only works if element is is block
.mar-cent {
  margin: 0 auto!important;
  &--max--md {
    @media screen and (max-width: $screen-md) {
      margin: 0 auto!important;
    }
  }
}


// center with margin auto – only works if parent element is flex
.content--center {
  margin: auto;
}

.block {
  display: block!important;
}

.inl-block {
  display: inline-block;
}

.flt-lft {
  float: left;
}

.flt-rgt {
  float: right!important;
  &--at--md {
  @media screen and (min-width: $screen-md) {
    float: right!important;
    }
  }
}

// Quick Position

.pos {
  &--rel {
    position: relative;
  }
  &--abs {
    position: absolute;
  }
  &--fxd {
    position: fixed;
  }
  &--stc {
    position: static;
  }
}

@each $size, $value in $sizes {
  .pos {
    &--top--#{$value} {
      top: $size;
    }
    &--rgt--#{$value} {
      right: $size;
    }
    &--btm--#{$value} {
      bottom: $size;
    }
    &--left--#{$value} {
      left: $size;
    }
  }
}

.has {
  &-ovrflw {
    &--hidden {
      @extend .pos--rel;
      overflow: hidden;
    }
  }
  &-topper {
    @extend .pos--rel;
  }
}

.o-flow {
  &-v {
    overflow-y: scroll;
  }
  &-hide {
    &-all {
      overflow: hidden;
    }
    &-hor {
      overflow-x: hidden;
    }
  }
}
.txt-up {
  text-transform: uppercase;
}

.txt-low {
  text-transform: lowercase;
}

.txt-sntnc {
  text-transform: none;
}

// Quickly center some text
.txt-c {
  text-align: center!important;
}

.txt-r {
  text-align: right!important;
}

.txt-l {
  text-align: left!important;
}

.flexbox {
  display: flex;
}

.flex-mar {
  .flexbox & {
    margin: auto;
  }
}

.flex {
  &-align {
    &-left {
      flex-wrap: wrap;
      align-items: flex-end;
    }
  }
}

.mar-0 {
  margin: 0 auto!important;
}

.pad-0 {
  padding: 0!important;
}

// Helper class for quickly making things fill their parent
.full-width {
  width: 100%;
}

// make it bold
.strong {
  font-weight: 700!important;
}

//make it bold af
.strongest {
  font-weight: 900!important;
}

//make it light
.lightweight {
  font-weight: 400!important;
}

//make it light af
.extralightweight {
  font-weight: 100!important;
}

.fw {
  // make it bold
  &--bold {
    font-weight: 700!important;
    //make it as bold as possible
    &est {
      font-weight: 900!important;
    }
  }
  &--std {
    font-weight: 400!important;
  }
  &--light {
    font-weight: 300!important;
    &est {
      font-weight: 100!important;
    }
  }
}

// to make a thing be 100% wide for fluid goodness
.fluid {
  width: 100%;
  &--full {
    height: 100%;
  }
}

// font-sizes

@each $size, $value in $sizes {
  .fs {
    &--#{$value} {
      font-size: $size;
    }
  }
}

.lsp {
  &--1 {
    letter-spacing: 0.1rem;
  }
  &--2 {
    letter-spacing: 0.2rem;
  }
}

//padding & Margins (Spacing)
// $sizes map is currently located in _structure.scss

@each $size, $value in $sizes {
  %p, .p {
    &--#{$value} {
      padding-top: $size;
      padding-bottom: $size;
    }
    &--all--#{$value} {
      padding: $size;
    }
    &--top--#{$value} {
      padding-top: $size;
    }
    &--right--#{$value} {
      padding-right: $size;
    }
    &--btm--#{$value} {
      padding-bottom: $size;
    }
    &--left--#{$value} {
      padding-left: $size;
    }
  }
  %m, .m {
    &--all--#{$value} {
      margin: $size;
    }
    &--top--#{$value} {
      margin-top: $size;
    }
    &--rgt--#{$value} {
      margin-right: $size;
    }
    &--btm--#{$value} {
      margin-bottom: $size;
    }
    &--lft--#{$value} {
      margin-left: $size;
    }
  }
}


.lead {
  @extend .strongest;
}

.rotate--180 {
  transform: rotate(180deg);
}

// for iframes & absolute positioned wrappers.
.pad-ratio {
  &--16x9 {
    @include aspect-ratio(16, 9);
  }
  &--4x3 {
    @include aspect-ratio(4, 3);
  }
  &--2x1 {
    @include aspect-ratio(2, 1);
  }
  &--1x1 {
    @include aspect-ratio(1, 1);
  }
  &--sm {
    &--1x1 {
      @media screen and (max-width: $screen-sm) {
        @include aspect-ratio(1, 1);
      }
    }
    &--4x3 {
      @media screen and (max-width: $screen-sm) {
        @include aspect-ratio(4, 3);
      }
    }
    &--32x41 {
      @media screen and (max-width: $screen-sm) {
        @include aspect-ratio(32, 41);
      }
    }
  }
}

.iframe-responsive {
  width: 100%;
  height: 100%;
}

.border {
  &--sm {
    border: solid 0.5rem;
  }
}

.img {
  &--fluid {
    max-width: 100%;
    height: auto;
    width: 100%;
  }
}

// Hover FX
.has {
  &--shadow {
    box-shadow: 0rem $xs $sm -0.35rem rgba($color_shadow, 0.3), 0rem 8rem $md -8.8rem;
    &--cent {
      &--sm {
        box-shadow: 0 0 math.div($xsm, 2) -0.35rem rgba($color_shadow, 0.3);
      }
    }
  }
  &--hover-fx--shadow {
    box-shadow: 0rem 0rem 0rem 0rem rgba($color_shadow, 0);
    transition: box-shadow 0.25s ease-in-out;
    &:hover, &:focus {
      box-shadow: 0rem $xs $sm -0.35rem rgba($color_shadow, 0.3), 0rem 8rem $md -8.8rem rgba($color_shadow, 0.6);
    }
  }
}

.separator {
  display: block;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  @include color-modifiers(background-color);
}

.bg {
  @include advanced-color-modifiers();
}

.txt {
  @include color-modifiers();
}

.cursor {
  &--cross {
    cursor: crosshair;
  }
  &--grab {
    cursor: grab;
  }
}

.no-border {
  border: none!important;
}

.hide {
  &--md {
    &--up {
      @include breakpoint($screen-md) {
        display: none;
      }
    }
  }
}