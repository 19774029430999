@use "sass:math";
.specification-panel {
  border: $border_style_global;
  border-radius: $border_radius_global;
  width: 100%;
  @include breakpoint(0 $screen-md) {
    margin: 0 auto;
  }
  .inner {
    .specification-component {
      h4 {
        text-align: center;
        color: $color_text_main;
      }
      .input-wrapper {
        .row {
          margin-left: 0;
          margin-right: 0;
          justify-content: space-between;
          padding: $xxs $lg;
          .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-12{
            padding: 0;
          }
          .col-sm-4{
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .col-sm-7{
            button, ul{
              span, li{
                font-size: $smd;
                font-family: $font_sans_2;
                font-family: var(--font-alt);
                font-weight: 300;
              }
            }
          }
          label {

          }
          #spec-title-label {
            display: block;
            text-align: center;
            font-size: 14px;
            font-size: 0.875rem;
            font-weight: 700;
          }
          .spec-label {
            display: block;
            text-align: center;
            padding-right: 2em;
          }
           p {
            color: $color_text_main;
            font-weight: 300;
            font-size: 11px;
            font-size: 0.6875rem;
            margin: 0;
          }
          .dropdown {
            display: inline-block !important;
            width: inherit;
            .dropdown-toggle{
              min-height: 48px;
              width: inherit;
              background-color: $color_main_background;
              border: $border_style_global;
              border-radius: $border_radius_global;
              &::after{
                border: solid black;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                position: absolute;
                right: 1.5rem;
                top: $smd;
              }
            }
            .dropdown-menu {
              width: 100%;
              li {
                a{
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                }
              }
            }
            .dropdown#ddlFormat .dropdown-menu {
              min-width: 215px !important;
            }
          }
          .one-option {
            .dropdown-toggle {
              cursor: default;
              &::after{
                display: none;
              }
            }
          }
          .textInput {
            input {
              display: block;
              width: 100%;
              min-height: 48px;
              text-align: center;
              border: none;
              padding: 0 0.3em;
              border-radius: 15px;
              line-height: 1em;
              background-color: transparent;
              color: $color_text_main;
              line-height: 20px;
              overflow: hidden;
              margin-bottom: 4px;
              font-size: $smd;
              font-family: $font_sans_2;
              font-family: var(--font-alt);
              font-weight: 300;
              border: $border_style_global;
              border-radius: $border_radius_global;
              &:focus {
                outline: none;
              }
            }
            .multiple {
              display: block;
              width: 100%;
              height: 20px;
              text-align: center;
              border: none;
              padding: 0 0.3em;
              border-radius: 15px;
              line-height: 1em;
              background-color: transparent;
              color: $color_text_main;
              line-height: 20px;
              font-size: 12px;
              font-size: 0.75rem;
              overflow: hidden;
              margin-bottom: 4px;
              &:focus {
                outline: none;
              }
              input {
                box-shadow: none;
              }
              .dimensions {
                input,
                span {
                  float: left;
                }
                input {
                  width: 45%;
                  &:first-of-type {
                    text-align: right;
                  }
                  &:last-of-type {
                    text-align: left;
                  }
                }
                span {
                  width: 10%;
                }
              }
              .label-only-view {
                box-shadow: none;
              }
            }
            label {
              display: block;
              text-align: center;
              padding-right: 20px;
              font-weight: normal;
            }
          }
          .locations {
            display: block;
            float: left;
            margin-top: 10px;
            input {
              width: 15px !important;
              height: 15px;
              opacity: 1;
              float: left;
              width: initial;
              border: 0;
              box-shadow: none;
              margin: 0;
              padding: 0;
              position: static;
              top: auto;
              left: auto;
            }
            span {
              float: left;
              margin-left: 5px;
              margin-right: 15px;
              color: #808080;
              font-size: 0.75rem;
            }
            a {
              float: left;
              &:first-of-type {
                margin-right: 20px;
              }
            }
          }
          .divider {
            width: 100%;
            height: 25px;
            color: #808080;
            position: relative;
            margin-bottom: 5px;
            &:before, &:after {
              content: "";
            }
            .divider-line,
            .divider-label {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            .divider-line {
              height: 10px;
              border-bottom: 1px solid #808080;
            }
            .divider-label {
              text-align: center;
              span {
                background-color: #FFF;
                padding: 0 5px;
                font-weight: bold;
                font-size: 0.75rem;
              }
            }
          }
          .turnaround-cost-wrapper, .turnaround-time-wrapper{
            display: none;
          }
        }
        #delivery-row {
          padding-top: 3.0rem;
          div {
            border-bottom: 1px solid $color_text_main;
            padding-bottom: 1.5rem;
            p {
              font-size: $smd;
            }
          }
        }
        #cost-row {
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 1.5rem;
          color: $color_brand_primary;
          label{
            font-weight: 700;
            font-size: $md;
          }
          #lblCost,
          #lblError {
            text-align: right;
            display: none;
            font-weight: 700;
            font-size: $md;
          }
          #lblError {
            color: #bf0500;
            font-size: 14px;
          }
        }
        #pricing-preferences{
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 1.0rem;
          .pricing-preference{
            color: $_grey_light;
            font-size: $xs;

            font-family: $font_sans_2;
            font-family: var(--font-alt);
          }
        }
        .turnaround-wrapper{
          background-color: $color_section_light_background;
          color: $_grey_light;
          display: none;
          margin-top: 1.7rem;
          #turnaround-options, #turnaround-times{
            justify-content: space-between;
            .turnaround-time-wrapper, .turnaround-cost-wrapper{
              .turnaround-time-label, .turnaround-cost-label{
                margin-right: 0.4rem;
              }
              div, span{

              }
            }
          }
          #turnaround-options{
            .turnaround-cost-wrapper {
              font-size: $xsm;
              font-family: $font_sans_2;
              font-family: var(--font-alt);
              .turnaround-cost-label {
                font-size: $xsm;
                text-transform: capitalize;
              }
            }
          }
          #turnaround-times{
            .turnaround-time-wrapper {
              font-size: $xs;
              font-family: $font_sans_2;
              font-family: var(--font-alt);
              .turnaround-time-label {
                font-size: $xs;
              }
            }
          }
        }
      }
      .order-button{
        text-align: center;
        margin-top: $lg;
        .btn{
          width: 86%;
          margin: 0 7%;
        }
      }
    }
  }
  .loaded .inner .specification-component .input-wrapper {
    visibility: visible;
    opacity: 1;
  }
}

.pop-up-window.upload-artwork {
  .upload-guide-text {

  }

  .uploading-indicator {
    display: none;

    .radial-progress {
      $circle-size: 120px;
      $circle-background: #d6dadc;
      $circle-color: #809db7; // 97a71d
      $inset-size: 90px;
      $inset-color: #fbfbfb;
      $transition-length: 1s;
      $shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);

      margin: auto;
      width: $circle-size;
      height: $circle-size;

      background-color: $circle-background;
      border-radius: 50%;
      .radial-progress-circle {
        .radial-progress-mask, .fill, .shadow {
          width: $circle-size;
          height: $circle-size;
          position: absolute;
          border-radius: 50%;
        }
        .shadow {
          box-shadow: $shadow inset;
        }
        .radial-progress-mask, .fill {
          -webkit-backface-visibility: hidden;
          transition: -webkit-transform $transition-length;
          transition: -ms-transform $transition-length;
          transition: transform $transition-length;
        }
        .radial-progress-mask {
          clip: rect(0px, $circle-size, $circle-size, math.div($circle-size, 2));
          .fill {
            clip: rect(0px, math.div($circle-size, 2), $circle-size, 0px);
            background-color: $circle-color;
          }
        }
      }
      .inset {
        width: $inset-size;
        height: $inset-size;
        position: absolute;
        margin-left: math.div($circle-size - $inset-size, 2);
        margin-top: math.div($circle-size - $inset-size, 2);

        background-color: $inset-color;
        border-radius: 50%;
        box-shadow: $shadow;
      }
    }
  }
}

.spec-panel-heading {
  font-size: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  padding: 4rem;
  margin-bottom: 0;
}