.hero {
  &__container {
    width: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
    @include breakpoint(min-width $screen-md) {
      min-height: 65.9rem;
      &.hero-shorter {
        min-height: initial;
        height: auto;
      }
    }
    img {
      @include breakpoint(max-width $screen-md) {
        object-fit: cover;
      }
    }
    &:focus {
      outline: none;
    }
    &--flat {
      @extend .hero__container;
      min-height: initial;
      @include clearfix;
    }
      &.splitboxes {
        &.hero {
          &--top {
            @include breakpoint($screen-md) {
              margin-top: $xl;
            }

            @include breakpoint($screen-lg) {
              margin-top: $xxxl;
            }
          }
          &--bottom {
            @include breakpoint($screen-md) {
              margin-bottom: $xl;
            }
            @include breakpoint($screen-lg) {
              margin-bottom: $xxxl;
            }
          }
        }
      }
  }
  &__contents {
    &__wrapper {
      display: flex;
      flex-flow: wrap-reverse;
      @include breakpoint($screen-md) {
        @include position(absolute, 0, 0, 0, 0);
        .hero__container--flat & {
          position: relative;
        }
        max-width: $site-max-width;
        margin: 0 auto;
        .hero-shorter & {
          position: relative;
        }
      }
      &.columns-reversed {

        @include breakpoint($screen-md) {
          flex-direction: row-reverse;
          .splitboxes & {
            //align-content: center;
             justify-content: flex-end;
          .hero__image {
              //margin-right: 3.2rem;
            }
            .hero__overlay {
              @include grid-column(8);
            }
          }
        }

      }
    }
  }
  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color_text_secondary;
    padding: $lg;
    height: 100%;
    @include grid-column(24, $gutterless-grid);
    @include breakpoint($screen-md) {
      padding: $xxl $lg;
      @include grid-column(10, $gutterless-grid);
      .splitboxes & {
        padding: $lg;
        @include grid-column(8);
      }
    }
    &__content {
      margin: auto;
      .hero__container--flat & {
        margin: 0;
      }
      h1 {
        margin-bottom: $md;
        font-size: 2rem;
        @include breakpoint($screen-md) {
          font-size: 5rem;
        }
        line-height: 1.25;
        @include interpolate(font-size, 320px, 1900px, 22px, 40px);
        .hero__container--flat & {
          margin-bottom: 0;
        }
      }
      p {
        font-size: 1.6rem;
        @include breakpoint($screen-md) {
          font-size: 2.6rem;
        }
        @include interpolate(font-size, 320px, 1900px, 16px, 26px);
      }
    }
  }
  &__image {
    height: 100%;
    @include grid-column(24, $gutterless-grid);
    margin-bottom: $smd;
    @include breakpoint($screen-md) {
      margin-bottom: 0;
      @include grid-column(14, $gutterless-grid);
      .splitboxes & {
        @include grid-column(16);
      }
    }

    .hero-shorter & {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    picture {
      height: 100%;
      width: 100%;
      display: block;
      .hero-shorter & {
        height: auto;
        width: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        .hero-shorter & {
          object-fit: contain;
        }
      }
    }
  }
}