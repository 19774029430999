@include meyer-reset;

$color_highlighted: $color_brand_primary;
$color_highlighted_text: $_white;
$color_shadow: $color_brand_primary;

strong, b, strong *, b * { font-weight: bold; }
em, i, em *, i * { font-style: italic; }

html, body {
  font-size: 62.5%;
  margin: 0 auto;
  height: 100%;
  @include backgroundAndColor($color_body_background, $color_text_main);
}

body {

  //padding-top: $site-nav-height;
}

header {
  @include breakpoint($screen-md) {
    border-bottom: 1px solid;
  }
}

.title {
  text-align: center;
}

html, body, .site__wrapper {
  overflow-x: hidden;
  position: relative;
}

/* Webkit */
::selection {
  background-color: $color_highlighted;
  color: $color_highlighted_text;
}

/* Gecko/Mozilla */
::-moz-selection {
  background-color: $color_highlighted;
  color: $color_highlighted_text;
}

img {
  width: 100%;
  height: auto;
}