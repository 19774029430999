@charset "UTF-8";
/*!
Theme Name: Stormpress 2021
Theme URI: stormpress.co.uk
Description: Bespoke theme made for Stormpress
Version: 1.0
*/
.col--1 {
  width: calc(4.1666666667% - 2.5rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--1 {
  margin-left: calc(4.1666666667% - 2.5rem + 4.8rem);
}

.col--2 {
  width: calc(8.3333333333% - 2.6rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--2 {
  margin-left: calc(8.3333333333% - 2.6rem + 4.8rem);
}

.col--3 {
  width: calc(12.5% - 2.7rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--3 {
  margin-left: calc(12.5% - 2.7rem + 4.8rem);
}

.col--4 {
  width: calc(16.6666666667% - 2.8rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--4 {
  margin-left: calc(16.6666666667% - 2.8rem + 4.8rem);
}

.col--5 {
  width: calc(20.8333333333% - 2.9rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--5 {
  margin-left: calc(20.8333333333% - 2.9rem + 4.8rem);
}

.col--6 {
  width: calc(25% - 3rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--6 {
  margin-left: calc(25% - 3rem + 4.8rem);
}

.col--7 {
  width: calc(29.1666666667% - 3.1rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--7 {
  margin-left: calc(29.1666666667% - 3.1rem + 4.8rem);
}

.col--8 {
  width: calc(33.3333333333% - 3.2rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--8 {
  margin-left: calc(33.3333333333% - 3.2rem + 4.8rem);
}

.col--9 {
  width: calc(37.5% - 3.3rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--9 {
  margin-left: calc(37.5% - 3.3rem + 4.8rem);
}

.col--10 {
  width: calc(41.6666666667% - 3.4rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--10 {
  margin-left: calc(41.6666666667% - 3.4rem + 4.8rem);
}

.col--11 {
  width: calc(45.8333333333% - 3.5rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--11 {
  margin-left: calc(45.8333333333% - 3.5rem + 4.8rem);
}

.col--12 {
  width: calc(50% - 3.6rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--12 {
  margin-left: calc(50% - 3.6rem + 4.8rem);
}

.col--13 {
  width: calc(54.1666666667% - 3.7rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--13 {
  margin-left: calc(54.1666666667% - 3.7rem + 4.8rem);
}

.col--14 {
  width: calc(58.3333333333% - 3.8rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--14 {
  margin-left: calc(58.3333333333% - 3.8rem + 4.8rem);
}

.col--15 {
  width: calc(62.5% - 3.9rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--15 {
  margin-left: calc(62.5% - 3.9rem + 4.8rem);
}

.col--16 {
  width: calc(66.6666666667% - 4rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--16 {
  margin-left: calc(66.6666666667% - 4rem + 4.8rem);
}

.col--17 {
  width: calc(70.8333333333% - 4.1rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--17 {
  margin-left: calc(70.8333333333% - 4.1rem + 4.8rem);
}

.col--18 {
  width: calc(75% - 4.2rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--18 {
  margin-left: calc(75% - 4.2rem + 4.8rem);
}

.col--19 {
  width: calc(79.1666666667% - 4.3rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--19 {
  margin-left: calc(79.1666666667% - 4.3rem + 4.8rem);
}

.col--20 {
  width: calc(83.3333333333% - 4.4rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--20 {
  margin-left: calc(83.3333333333% - 4.4rem + 4.8rem);
}

.col--21 {
  width: calc(87.5% - 4.5rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--21 {
  margin-left: calc(87.5% - 4.5rem + 4.8rem);
}

.col--22 {
  width: calc(91.6666666667% - 4.6rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--22 {
  margin-left: calc(91.6666666667% - 4.6rem + 4.8rem);
}

.col--23 {
  width: calc(95.8333333333% - 4.7rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--23 {
  margin-left: calc(95.8333333333% - 4.7rem + 4.8rem);
}

.col--24 {
  width: calc(100% - 4.8rem);
  float: left;
  margin-left: 2.4rem;
}

.col--push--24 {
  margin-left: calc(100% - 4.8rem + 4.8rem);
}

.grid-row {
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  width: calc(100% + 4.8rem);
}

.banner, .grid__wrapper, .contact__wrapper, .general-content__wrapper, .content__wrapper, .list-section--flex, .review__blocks {
  max-width: 1644px;
  display: block;
  width: 100%;
  margin: 0 auto;
}
.banner::after, .grid__wrapper::after, .contact__wrapper::after, .general-content__wrapper::after, .content__wrapper::after, .list-section--flex::after, .review__blocks::after {
  clear: both;
  content: "";
  display: block;
}
.list-section--flex, .review__blocks {
  display: flex;
  height: inherit;
}

.site__wrapper {
  display: block;
  height: inherit;
}

.no__hero__spacer {
  height: 14.7rem;
}
@media (min-width: 992px) {
  .no__hero__spacer {
    height: 19.7rem;
  }
}

.contacts__wrapper {
  width: calc(50% - 3.6rem);
  float: left;
  margin-left: 2.4rem;
}
@media (min-width: 992px) {
  .contacts__wrapper {
    width: calc(20.8333333333% - 2.9rem);
    float: left;
    margin-left: 2.4rem;
  }
}

.section, .section--padx2 {
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 992px) {
  .section, .section--padx2 {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }
}
.section::after, .section--padx2::after {
  clear: both;
  content: "";
  display: block;
}
@media (min-width: 992px) {
  .section--padx2 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .section--padx2 + .section--padx2 {
    padding-top: 0;
  }
}
.section__contact {
  padding-right: 0;
  padding-left: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong, b, strong *, b * {
  font-weight: bold;
}

em, i, em *, i * {
  font-style: italic;
}

html, body {
  font-size: 62.5%;
  margin: 0 auto;
  height: 100%;
  background-color: #FFFFFF;
  color: #413E3A;
}

@media (min-width: 992px) {
  header {
    border-bottom: 1px solid;
  }
}

.title {
  text-align: center;
}

html, body, .site__wrapper {
  overflow-x: hidden;
  position: relative;
}

/* Webkit */
::selection {
  background-color: #375773;
  color: #FFFFFF;
}

/* Gecko/Mozilla */
::-moz-selection {
  background-color: #375773;
  color: #FFFFFF;
}

img {
  width: 100%;
  height: auto;
}

/*

Utility classes do exactly what they say on the tin — they're
especially helpful for rapid prototyping

*/
.clearfix:before, .order__overview__row:before, .order__address__row:before, .clearfix:after, .order__overview__row:after, .order__address__row:after {
  display: table;
  width: 100%;
  clear: both;
  content: "";
}

.screenreader {
  position: absolute !important;
  /* Outside the DOM flow */
  height: 1px;
  width: 1px;
  /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px);
  /* All other browsers */
}

.backgroundblendmode .blend--overlay {
  mix-blend-mode: overlay;
}
.backgroundblendmode .blend--multiply {
  mix-blend-mode: multiply;
}
.backgroundblendmode .blend--screen {
  mix-blend-mode: screen;
}
.backgroundblendmode .blend--difference {
  mix-blend-mode: difference;
}
.backgroundblendmode .blend--colorburn {
  mix-blend-mode: color-burn;
}

.no-backgroundblendmode .blend--overlay, .no-backgroundblendmode .blend--multiply, .no-backgroundblendmode .blend--screen, .no-backgroundblendmode .blend--difference, .no-backgroundblendmode .blend--colorburn {
  opacity: 0.5;
}

.mar-cent {
  margin: 0 auto !important;
}
@media screen and (max-width: 992px) {
  .mar-cent--max--md {
    margin: 0 auto !important;
  }
}

.content--center {
  margin: auto;
}

.block {
  display: block !important;
}

.inl-block {
  display: inline-block;
}

.flt-lft {
  float: left;
}

.flt-rgt {
  float: right !important;
}
@media screen and (min-width: 992px) {
  .flt-rgt--at--md {
    float: right !important;
  }
}

.pos--rel, .has-topper, .has-ovrflw--hidden {
  position: relative;
}
.pos--abs {
  position: absolute;
}
.pos--fxd {
  position: fixed;
}
.pos--stc {
  position: static;
}

.pos--top--0 {
  top: 0;
}
.pos--rgt--0 {
  right: 0;
}
.pos--btm--0 {
  bottom: 0;
}
.pos--left--0 {
  left: 0;
}

.pos--top--xxs {
  top: 1rem;
}
.pos--rgt--xxs {
  right: 1rem;
}
.pos--btm--xxs {
  bottom: 1rem;
}
.pos--left--xxs {
  left: 1rem;
}

.pos--top--xs {
  top: 1.2rem;
}
.pos--rgt--xs {
  right: 1.2rem;
}
.pos--btm--xs {
  bottom: 1.2rem;
}
.pos--left--xs {
  left: 1.2rem;
}

.pos--top--xsm {
  top: 1.6rem;
}
.pos--rgt--xsm {
  right: 1.6rem;
}
.pos--btm--xsm {
  bottom: 1.6rem;
}
.pos--left--xsm {
  left: 1.6rem;
}

.pos--top--sm {
  top: 1.8rem;
}
.pos--rgt--sm {
  right: 1.8rem;
}
.pos--btm--sm {
  bottom: 1.8rem;
}
.pos--left--sm {
  left: 1.8rem;
}

.pos--top--smd {
  top: 2rem;
}
.pos--rgt--smd {
  right: 2rem;
}
.pos--btm--smd {
  bottom: 2rem;
}
.pos--left--smd {
  left: 2rem;
}

.pos--top--md {
  top: 2.4rem;
}
.pos--rgt--md {
  right: 2.4rem;
}
.pos--btm--md {
  bottom: 2.4rem;
}
.pos--left--md {
  left: 2.4rem;
}

.pos--top--mdl {
  top: 2.6rem;
}
.pos--rgt--mdl {
  right: 2.6rem;
}
.pos--btm--mdl {
  bottom: 2.6rem;
}
.pos--left--mdl {
  left: 2.6rem;
}

.pos--top--lg {
  top: 4rem;
}
.pos--rgt--lg {
  right: 4rem;
}
.pos--btm--lg {
  bottom: 4rem;
}
.pos--left--lg {
  left: 4rem;
}

.pos--top--xl {
  top: 5rem;
}
.pos--rgt--xl {
  right: 5rem;
}
.pos--btm--xl {
  bottom: 5rem;
}
.pos--left--xl {
  left: 5rem;
}

.pos--top--xxl {
  top: 8rem;
}
.pos--rgt--xxl {
  right: 8rem;
}
.pos--btm--xxl {
  bottom: 8rem;
}
.pos--left--xxl {
  left: 8rem;
}

.pos--top--xxxl {
  top: 10rem;
}
.pos--rgt--xxxl {
  right: 10rem;
}
.pos--btm--xxxl {
  bottom: 10rem;
}
.pos--left--xxxl {
  left: 10rem;
}

.has-ovrflw--hidden {
  overflow: hidden;
}
.o-flow-v {
  overflow-y: scroll;
}
.o-flow-hide-all {
  overflow: hidden;
}
.o-flow-hide-hor {
  overflow-x: hidden;
}

.txt-up {
  text-transform: uppercase;
}

.txt-low {
  text-transform: lowercase;
}

.txt-sntnc {
  text-transform: none;
}

.txt-c {
  text-align: center !important;
}

.txt-r {
  text-align: right !important;
}

.txt-l {
  text-align: left !important;
}

.flexbox {
  display: flex;
}

.flexbox .flex-mar {
  margin: auto;
}

.flex-align-left {
  flex-wrap: wrap;
  align-items: flex-end;
}

.mar-0 {
  margin: 0 auto !important;
}

.pad-0 {
  padding: 0 !important;
}

.full-width {
  width: 100%;
}

.strong {
  font-weight: 700 !important;
}

.strongest, .lead {
  font-weight: 900 !important;
}

.lightweight {
  font-weight: 400 !important;
}

.extralightweight {
  font-weight: 100 !important;
}

.fw--bold {
  font-weight: 700 !important;
}
.fw--boldest {
  font-weight: 900 !important;
}
.fw--std {
  font-weight: 400 !important;
}
.fw--light {
  font-weight: 300 !important;
}
.fw--lightest {
  font-weight: 100 !important;
}

.fluid {
  width: 100%;
}
.fluid--full {
  height: 100%;
}

.fs--0 {
  font-size: 0;
}

.fs--xxs {
  font-size: 1rem;
}

.fs--xs {
  font-size: 1.2rem;
}

.fs--xsm {
  font-size: 1.6rem;
}

.fs--sm {
  font-size: 1.8rem;
}

.fs--smd {
  font-size: 2rem;
}

.fs--md {
  font-size: 2.4rem;
}

.fs--mdl {
  font-size: 2.6rem;
}

.fs--lg {
  font-size: 4rem;
}

.fs--xl {
  font-size: 5rem;
}

.fs--xxl {
  font-size: 8rem;
}

.fs--xxxl {
  font-size: 10rem;
}

.lsp--1 {
  letter-spacing: 0.1rem;
}
.lsp--2 {
  letter-spacing: 0.2rem;
}

.p--0 {
  padding-top: 0;
  padding-bottom: 0;
}
.p--all--0 {
  padding: 0;
}
.p--top--0 {
  padding-top: 0;
}
.p--right--0 {
  padding-right: 0;
}
.p--btm--0 {
  padding-bottom: 0;
}
.p--left--0 {
  padding-left: 0;
}

.m--all--0 {
  margin: 0;
}
.m--top--0 {
  margin-top: 0;
}
.m--rgt--0 {
  margin-right: 0;
}
.m--btm--0 {
  margin-bottom: 0;
}
.m--lft--0 {
  margin-left: 0;
}

.p--xxs {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.p--all--xxs {
  padding: 1rem;
}
.p--top--xxs {
  padding-top: 1rem;
}
.p--right--xxs {
  padding-right: 1rem;
}
.p--btm--xxs {
  padding-bottom: 1rem;
}
.p--left--xxs {
  padding-left: 1rem;
}

.m--all--xxs {
  margin: 1rem;
}
.m--top--xxs {
  margin-top: 1rem;
}
.m--rgt--xxs {
  margin-right: 1rem;
}
.m--btm--xxs {
  margin-bottom: 1rem;
}
.m--lft--xxs {
  margin-left: 1rem;
}

.p--xs {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
.p--all--xs {
  padding: 1.2rem;
}
.p--top--xs {
  padding-top: 1.2rem;
}
.p--right--xs {
  padding-right: 1.2rem;
}
.p--btm--xs {
  padding-bottom: 1.2rem;
}
.p--left--xs {
  padding-left: 1.2rem;
}

.m--all--xs {
  margin: 1.2rem;
}
.m--top--xs {
  margin-top: 1.2rem;
}
.m--rgt--xs {
  margin-right: 1.2rem;
}
.m--btm--xs {
  margin-bottom: 1.2rem;
}
.m--lft--xs {
  margin-left: 1.2rem;
}

.p--xsm {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.p--all--xsm {
  padding: 1.6rem;
}
.p--top--xsm {
  padding-top: 1.6rem;
}
.p--right--xsm {
  padding-right: 1.6rem;
}
.p--btm--xsm {
  padding-bottom: 1.6rem;
}
.p--left--xsm {
  padding-left: 1.6rem;
}

.m--all--xsm {
  margin: 1.6rem;
}
.m--top--xsm {
  margin-top: 1.6rem;
}
.m--rgt--xsm {
  margin-right: 1.6rem;
}
.m--btm--xsm {
  margin-bottom: 1.6rem;
}
.m--lft--xsm {
  margin-left: 1.6rem;
}

.p--sm {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}
.p--all--sm {
  padding: 1.8rem;
}
.p--top--sm {
  padding-top: 1.8rem;
}
.p--right--sm {
  padding-right: 1.8rem;
}
.p--btm--sm {
  padding-bottom: 1.8rem;
}
.p--left--sm {
  padding-left: 1.8rem;
}

.m--all--sm {
  margin: 1.8rem;
}
.m--top--sm {
  margin-top: 1.8rem;
}
.m--rgt--sm {
  margin-right: 1.8rem;
}
.m--btm--sm {
  margin-bottom: 1.8rem;
}
.m--lft--sm {
  margin-left: 1.8rem;
}

.p--smd {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.p--all--smd {
  padding: 2rem;
}
.p--top--smd {
  padding-top: 2rem;
}
.p--right--smd {
  padding-right: 2rem;
}
.p--btm--smd {
  padding-bottom: 2rem;
}
.p--left--smd {
  padding-left: 2rem;
}

.m--all--smd {
  margin: 2rem;
}
.m--top--smd {
  margin-top: 2rem;
}
.m--rgt--smd {
  margin-right: 2rem;
}
.m--btm--smd {
  margin-bottom: 2rem;
}
.m--lft--smd {
  margin-left: 2rem;
}

.p--md {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}
.p--all--md {
  padding: 2.4rem;
}
.p--top--md {
  padding-top: 2.4rem;
}
.p--right--md {
  padding-right: 2.4rem;
}
.p--btm--md {
  padding-bottom: 2.4rem;
}
.p--left--md {
  padding-left: 2.4rem;
}

.m--all--md {
  margin: 2.4rem;
}
.m--top--md {
  margin-top: 2.4rem;
}
.m--rgt--md {
  margin-right: 2.4rem;
}
.m--btm--md {
  margin-bottom: 2.4rem;
}
.m--lft--md {
  margin-left: 2.4rem;
}

.p--mdl {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
}
.p--all--mdl {
  padding: 2.6rem;
}
.p--top--mdl {
  padding-top: 2.6rem;
}
.p--right--mdl {
  padding-right: 2.6rem;
}
.p--btm--mdl {
  padding-bottom: 2.6rem;
}
.p--left--mdl {
  padding-left: 2.6rem;
}

.m--all--mdl {
  margin: 2.6rem;
}
.m--top--mdl {
  margin-top: 2.6rem;
}
.m--rgt--mdl {
  margin-right: 2.6rem;
}
.m--btm--mdl {
  margin-bottom: 2.6rem;
}
.m--lft--mdl {
  margin-left: 2.6rem;
}

.p--lg {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.p--all--lg {
  padding: 4rem;
}
.p--top--lg {
  padding-top: 4rem;
}
.p--right--lg {
  padding-right: 4rem;
}
.p--btm--lg {
  padding-bottom: 4rem;
}
.p--left--lg {
  padding-left: 4rem;
}

.m--all--lg {
  margin: 4rem;
}
.m--top--lg {
  margin-top: 4rem;
}
.m--rgt--lg {
  margin-right: 4rem;
}
.m--btm--lg {
  margin-bottom: 4rem;
}
.m--lft--lg {
  margin-left: 4rem;
}

.p--xl {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.p--all--xl {
  padding: 5rem;
}
.p--top--xl {
  padding-top: 5rem;
}
.p--right--xl {
  padding-right: 5rem;
}
.p--btm--xl {
  padding-bottom: 5rem;
}
.p--left--xl {
  padding-left: 5rem;
}

.m--all--xl {
  margin: 5rem;
}
.m--top--xl {
  margin-top: 5rem;
}
.m--rgt--xl {
  margin-right: 5rem;
}
.m--btm--xl {
  margin-bottom: 5rem;
}
.m--lft--xl {
  margin-left: 5rem;
}

.p--xxl {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.p--all--xxl {
  padding: 8rem;
}
.p--top--xxl {
  padding-top: 8rem;
}
.p--right--xxl {
  padding-right: 8rem;
}
.p--btm--xxl {
  padding-bottom: 8rem;
}
.p--left--xxl {
  padding-left: 8rem;
}

.m--all--xxl {
  margin: 8rem;
}
.m--top--xxl {
  margin-top: 8rem;
}
.m--rgt--xxl {
  margin-right: 8rem;
}
.m--btm--xxl {
  margin-bottom: 8rem;
}
.m--lft--xxl {
  margin-left: 8rem;
}

.p--xxxl {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.p--all--xxxl {
  padding: 10rem;
}
.p--top--xxxl {
  padding-top: 10rem;
}
.p--right--xxxl {
  padding-right: 10rem;
}
.p--btm--xxxl {
  padding-bottom: 10rem;
}
.p--left--xxxl {
  padding-left: 10rem;
}

.m--all--xxxl {
  margin: 10rem;
}
.m--top--xxxl {
  margin-top: 10rem;
}
.m--rgt--xxxl {
  margin-right: 10rem;
}
.m--btm--xxxl {
  margin-bottom: 10rem;
}
.m--lft--xxxl {
  margin-left: 10rem;
}

.rotate--180 {
  transform: rotate(180deg);
}

.pad-ratio--16x9 {
  position: relative;
}
.pad-ratio--16x9:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.pad-ratio--16x9 > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pad-ratio--16x9 > .content__iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
.pad-ratio--4x3 {
  position: relative;
}
.pad-ratio--4x3:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}
.pad-ratio--4x3 > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pad-ratio--4x3 > .content__iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
.pad-ratio--2x1 {
  position: relative;
}
.pad-ratio--2x1:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 50%;
}
.pad-ratio--2x1 > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pad-ratio--2x1 > .content__iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
.pad-ratio--1x1 {
  position: relative;
}
.pad-ratio--1x1:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.pad-ratio--1x1 > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pad-ratio--1x1 > .content__iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
@media screen and (max-width: 768px) {
  .pad-ratio--sm--1x1 {
    position: relative;
  }
  .pad-ratio--sm--1x1:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .pad-ratio--sm--1x1 > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pad-ratio--sm--1x1 > .content__iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}
@media screen and (max-width: 768px) {
  .pad-ratio--sm--4x3 {
    position: relative;
  }
  .pad-ratio--sm--4x3:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  .pad-ratio--sm--4x3 > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pad-ratio--sm--4x3 > .content__iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}
@media screen and (max-width: 768px) {
  .pad-ratio--sm--32x41 {
    position: relative;
  }
  .pad-ratio--sm--32x41:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 128.125%;
  }
  .pad-ratio--sm--32x41 > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pad-ratio--sm--32x41 > .content__iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.iframe-responsive {
  width: 100%;
  height: 100%;
}

.border--sm {
  border: solid 0.5rem;
}

.img--fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.has--shadow {
  box-shadow: 0rem 1.2rem 1.8rem -0.35rem rgba(55, 87, 115, 0.3), 0rem 8rem 2.4rem -8.8rem;
}
.has--shadow--cent--sm {
  box-shadow: 0 0 0.8rem -0.35rem rgba(55, 87, 115, 0.3);
}
.has--hover-fx--shadow {
  box-shadow: 0rem 0rem 0rem 0rem rgba(55, 87, 115, 0);
  transition: box-shadow 0.25s ease-in-out;
}
.has--hover-fx--shadow:hover, .has--hover-fx--shadow:focus {
  box-shadow: 0rem 1.2rem 1.8rem -0.35rem rgba(55, 87, 115, 0.3), 0rem 8rem 2.4rem -8.8rem rgba(55, 87, 115, 0.6);
}

.separator {
  display: block;
  width: 100%;
  height: 2px;
  margin: 0 auto;
}
.separator--primary {
  background-color: #375773;
}
.separator--primary .icon--fill {
  fill: #375773;
}
.separator--secondary {
  background-color: #423E39;
}
.separator--secondary .icon--fill {
  fill: #423E39;
}
.separator--negative {
  background-color: #FFFFFF;
}
.separator--negative .icon--fill {
  fill: #FFFFFF;
}
.separator--positive {
  background-color: #000000;
}
.separator--positive .icon--fill {
  fill: #000000;
}

.bg--primary {
  background-color: #375773;
  color: #423E39;
}
.bg--primary .icon--fill {
  fill: #423E39;
}
.bg--secondary {
  background-color: #423E39;
  color: #423E39;
}
.bg--secondary .icon--fill {
  fill: #423E39;
}
.bg--negative {
  background-color: #FFFFFF;
  color: #423E39;
}
.bg--negative .icon--fill {
  fill: #423E39;
}
.bg--positive {
  background-color: #000000;
  color: #423E39;
}
.bg--positive .icon--fill {
  fill: #423E39;
}

.txt--primary {
  color: #375773;
}
.txt--primary .icon--fill {
  fill: #375773;
}
.txt--secondary {
  color: #423E39;
}
.txt--secondary .icon--fill {
  fill: #423E39;
}
.txt--negative {
  color: #FFFFFF;
}
.txt--negative .icon--fill {
  fill: #FFFFFF;
}
.txt--positive {
  color: #000000;
}
.txt--positive .icon--fill {
  fill: #000000;
}

.cursor--cross {
  cursor: crosshair;
}
.cursor--grab {
  cursor: grab;
}

.no-border {
  border: none !important;
}

@media (min-width: 992px) {
  .hide--md--up {
    display: none;
  }
}

@font-face {
  font-family: "ComfortaaVariable";
  font-style: normal;
  font-weight: 100 900;
  src: url("/style/fonts/comfortaa/Comfortaa-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "SofiaProWeb";
  font-style: normal;
  font-weight: 300;
  src: url("/style/fonts/SofiaPro/SofiaProLight-webfont.woff") format("woff"), url("/style/fonts/SofiaPro/SofiaProLight-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "SofiaProWeb";
  font-style: normal;
  font-weight: 400;
  src: url("/style/fonts/SofiaPro/SofiaProRegular-webfont.woff") format("woff"), url("/style/fonts/SofiaPro/SofiaProRegular-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "SofiaProWeb";
  font-style: normal;
  font-weight: 500;
  src: url("/style/fonts/SofiaPro/SofiaProMedium-webfont.woff") format("woff"), url("/style/fonts/SofiaPro/SofiaProMedium-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "SofiaProWeb";
  font-style: normal;
  font-weight: 600;
  src: url("/style/fonts/SofiaPro/SofiaProBold-webfont.woff") format("woff"), url("/style/fonts/SofiaPro/SofiaProBold-webfont.woff2") format("woff2");
}
:root {
  --font-main: ComfortaaVariable, Helvetica, Arial, sans-serif;
  --font-alt: SofiaProWeb, Helvetica, Arial, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  line-height: 1.25;
  font-size: 1.8rem;
}

p, address {
  line-height: 1.5;
  margin-bottom: 1em;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
  font-size: 1.8rem;
}
.contact__wrapper div p, .contact__wrapper div address {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.contact__wrapper div p:last-of-type, .contact__wrapper div address:last-of-type {
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}
p > a, p span a, label a, a.link__footer, h1 a, h2 a, h3 a, h4 a, h5 a, .h1 a, .h2 a, .h3 a, h2--small a, .h2--small a, .h4 a, .h5 a {
  color: #413E3A;
  text-decoration: none;
  padding: 0 0.4rem;
  display: inline;
  position: relative;
  font-weight: normal;
  z-index: 1;
  box-shadow: inset 0rem -0.2rem #FFFFFF;
  transition: box-shadow 0.25s ease-in-out;
}

.link__back {
  font-family: var(--font-alt);
  color: #375773;
  display: inline-block;
  margin-bottom: 5rem;
}

h1, h2, h3, h4, .h1, .h2, .h3, h2--small, .h2--small, .h4, h5, .h5 {
  font-weight: inherit;
  line-height: 1.25;
  text-transform: uppercase;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
}

h2, h3, h4, .h2, .h3, h2--small, .h2--small, .h4, h5, .h5 {
  margin-bottom: 1em;
}

h1, .h1 {
  font-size: 2rem;
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 5rem;
  }
}
h1 span, .h1 span {
  display: block;
}

h2, .h2 {
  font-size: 2.4rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  h2, .h2 {
    font-size: 4rem;
  }
}
h3, .h3, h2--small, .h2--small {
  font-size: 2rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  h3, .h3, h2--small, .h2--small {
    font-size: 2.4rem;
  }
}

ul {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.content-block ul {
  font-weight: 300;
  line-height: 1.5;
}

img + .wysiwyg {
  margin-top: 1.8rem;
  display: block;
}
.wysiwyg h2, .wysiwyg .h2 {
  font-size: 2.6rem;
  font-weight: 400;
}
.wysiwyg h3, .wysiwyg .h3, .wysiwyg h2--small, .wysiwyg .h2--small {
  font-weight: 200;
  font-size: 2.4rem;
}
.wysiwyg h4, .wysiwyg .h4 {
  font-size: 2rem;
  font-weight: 200;
}
.wysiwyg h5 {
  font-size: 1.8rem;
  font-weight: 600;
}
.wysiwyg h6 {
  font-size: 1.6rem;
  font-weight: 600;
}

small, .font_small, small p, .small p {
  font-size: 1.6rem;
}
.h1 small, h1 small, .h1 .font_small, h1 .font_small, .h1 small p, h1 small p, .h1 .small p, h1 .small p {
  font-size: 0.35em;
  padding-bottom: 4rem;
  display: block;
  letter-spacing: 0.01em;
}

sub, sup {
  /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
  font-size: 75%;
  /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
  line-height: 0;
  /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
  position: relative;
  /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
  vertical-align: baseline;
}

sup {
  /* Move the superscripted text up */
  top: -0.5em;
}

sub {
  /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
  bottom: -0.25em;
}

.wysiwyg ul, ul--basic, .wysiwyg .ul, .ul--basic, .wysiwyg ol, ol--basic, .wysiwyg .ol, .ol--basic {
  line-height: 1.6;
}
ul.unstyled, .ul.unstyled, ol.unstyled, .ol.unstyled {
  list-style: none;
}

.wysiwyg ul li {
  position: relative;
  margin-left: calc( 1.8rem + 0.4rem );
}

ol {
  list-style-type: none;
  counter-reset: item;
}
ol > li {
  display: table;
  counter-increment: item;
}
ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.hero__container, .hero__container--flat {
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}
@media (min-width: 992px) {
  .hero__container, .hero__container--flat {
    min-height: 65.9rem;
  }
  .hero__container.hero-shorter, .hero-shorter.hero__container--flat {
    min-height: initial;
    height: auto;
  }
}
@media (max-width: 992px) {
  .hero__container img, .hero__container--flat img {
    object-fit: cover;
  }
}
.hero__container:focus, .hero__container--flat:focus {
  outline: none;
}
.hero__container--flat {
  min-height: initial;
}
.hero__container--flat::after {
  clear: both;
  content: "";
  display: block;
}
@media (min-width: 992px) {
  .hero__container.splitboxes.hero--top, .splitboxes.hero--top.hero__container--flat {
    margin-top: 5rem;
  }
}
@media (min-width: 1200px) {
  .hero__container.splitboxes.hero--top, .splitboxes.hero--top.hero__container--flat {
    margin-top: 10rem;
  }
}
@media (min-width: 992px) {
  .hero__container.splitboxes.hero--bottom, .splitboxes.hero--bottom.hero__container--flat {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1200px) {
  .hero__container.splitboxes.hero--bottom, .splitboxes.hero--bottom.hero__container--flat {
    margin-bottom: 10rem;
  }
}
.hero__contents__wrapper {
  display: flex;
  flex-flow: wrap-reverse;
}
@media (min-width: 992px) {
  .hero__contents__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 1644px;
    margin: 0 auto;
  }
  .hero__container--flat .hero__contents__wrapper {
    position: relative;
  }
  .hero-shorter .hero__contents__wrapper {
    position: relative;
  }
}
@media (min-width: 992px) {
  .hero__contents__wrapper.columns-reversed {
    flex-direction: row-reverse;
  }
  .splitboxes .hero__contents__wrapper.columns-reversed {
    justify-content: flex-end;
  }
  .splitboxes .hero__contents__wrapper.columns-reversed .hero__overlay {
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.hero__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  padding: 4rem;
  height: 100%;
  width: calc(100%);
  float: left;
  margin-left: 0;
}
@media (min-width: 992px) {
  .hero__overlay {
    padding: 8rem 4rem;
    width: calc(41.6666666667%);
    float: left;
    margin-left: 0;
  }
  .splitboxes .hero__overlay {
    padding: 4rem;
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.hero__overlay__content {
  margin: auto;
}
.hero__container--flat .hero__overlay__content {
  margin: 0;
}
.hero__overlay__content h1 {
  margin-bottom: 2.4rem;
  font-size: 2rem;
  line-height: 1.25;
}
@media (min-width: 992px) {
  .hero__overlay__content h1 {
    font-size: 5rem;
  }
}
.hero__overlay__content h1 {
  font-size: 22px;
}
@media screen and (min-width: 320px) {
  .hero__overlay__content h1 {
    font-size: calc(1.1392405063vw + 18.3544303797px);
  }
}
@media screen and (min-width: 1900px) {
  .hero__overlay__content h1 {
    font-size: 40px;
  }
}
.hero__container--flat .hero__overlay__content h1 {
  margin-bottom: 0;
}
.hero__overlay__content p {
  font-size: 1.6rem;
}
@media (min-width: 992px) {
  .hero__overlay__content p {
    font-size: 2.6rem;
  }
}
.hero__overlay__content p {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .hero__overlay__content p {
    font-size: calc(0.6329113924vw + 13.9746835443px);
  }
}
@media screen and (min-width: 1900px) {
  .hero__overlay__content p {
    font-size: 26px;
  }
}
.hero__image {
  height: 100%;
  width: calc(100%);
  float: left;
  margin-left: 0;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .hero__image {
    margin-bottom: 0;
    width: calc(58.3333333333%);
    float: left;
    margin-left: 0;
  }
  .splitboxes .hero__image {
    width: calc(66.6666666667% - 4rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.hero-shorter .hero__image {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero__image picture {
  height: 100%;
  width: 100%;
  display: block;
}
.hero-shorter .hero__image picture {
  height: auto;
  width: auto;
}
.hero__image picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-shorter .hero__image picture img {
  object-fit: contain;
}

.logo__wrapper {
  display: block;
}
.logo__wrapper svg {
  display: block;
  width: 100%;
  height: auto;
}

input {
  -webkit-appearance: none;
  border: none;
  font-size: 1.6rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  padding: 2rem 2.4rem;
  width: 100%;
  height: 5.4rem;
  border-radius: 4px;
  border-radius: 4px;
  border-radius: 4px;
}

input[type=radio] {
  width: initial;
  -webkit-appearance: radio;
  height: initial;
}

.form-group {
  width: calc(100% - 4.8rem);
  float: left;
  margin-left: 2.4rem;
}
.form-group label {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.form-group__wrapper {
  margin-bottom: 1.8rem;
}
.form-group__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.form-group__wrapper label {
  margin-bottom: 1rem;
  display: inline-block;
  font-weight: 300;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
@media (min-width: 992px) {
  .form-group--half {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.form-group .legend {
  margin-bottom: 1.8rem;
  display: inline-block;
}
.form-group__radio[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  z-index: -1000;
  left: -62.5em;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.form-group__radio-label {
  background-color: transparent;
  padding-left: 2rem !important;
  text-indent: 0 !important;
  margin-bottom: 1.6rem;
  height: 2rem;
  display: block !important;
  line-height: 2rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  cursor: pointer;
  position: relative;
}
.form-group__radio-label:before, .form-group__radio-label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.form-group__radio-label:before {
  background-color: transparent;
  width: 1.2rem;
  height: 1.2rem;
  left: 5px;
  top: 5px;
  border: solid 1px #413E3A;
}
.form-group__radio-label:after {
  background-color: transparent;
  width: 0.6rem;
  height: 0.6rem;
  left: 0.8rem;
  top: 0.8rem;
  transform: scale(0);
}
.form-group__radio-label.is--checked:after {
  background-color: #413E3A;
  transform: scale(1);
}
.form-group .address-select__wrapper {
  position: relative;
  margin-bottom: 1.6rem;
  display: none;
  align-items: center;
  width: calc(100% + 1.8rem);
}
.form-group .address-select__wrapper:after {
  content: "";
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  border: 0.2rem solid;
  border-bottom: none;
  border-left: none;
  color: inherit;
  transform: rotate(135deg);
  transition: transform 0.15s ease-in-out;
  top: -10px;
  position: relative;
  right: 2.4rem;
}
.form-group .address-select__wrapper select {
  border: 1px solid #423E39;
  font-weight: 300;
  font-size: 1.8rem;
  font-family: inherit;
  width: 100%;
  appearance: none;
  border-radius: 4px;
  background-color: #ECEBEB;
}
.form-group .address-select__wrapper.is--displayed {
  display: flex;
}

.messages {
  margin: 2rem auto;
  padding: 2rem;
  color: #E41E1D;
  border: 1px solid #423E39;
  border-radius: 4px;
}

#txtEmail-error {
  display: block;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #423E39;
  border-radius: 4px;
  color: #E41E1D;
}

.form-control {
  height: 5rem;
  font-size: 2rem;
  background-color: #ECEBEB;
  border: 1px solid #423E39;
  width: 100%;
  margin-bottom: 1.6rem;
}

.validation-failed {
  border-color: #E41E1D;
  border-width: 0.2rem;
}

textarea.form-control {
  height: auto;
  border-radius: 4px;
  font-size: 2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  padding: 2rem 2.4rem;
}

.newsletter__input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.newsletter__input__wrapper {
  position: relative;
  padding: 0 2rem;
  max-width: 53rem;
  height: 5.4rem;
  margin: 2rem auto 3rem;
  float: none;
}
@media (min-width: 992px) {
  .newsletter__input__wrapper {
    margin: 2rem auto 6rem;
  }
}
.newsletter__input__wrapper label {
  opacity: 0;
}
.newsletter__input--submit {
  left: initial;
  width: 4rem;
  font-size: 0;
  background-color: rgba(112, 112, 112, 0);
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: background-color 0.25s ease-in-out;
}
.newsletter__input--submit:hover {
  background: rgba(112, 112, 112, 0.15);
}

.login__form input {
  border: solid 1px #413E3A;
  border-radius: 4px;
  background-color: #ECEBEB;
}

.g-recaptcha-bubble-arrow {
  display: none;
}

.g-recaptcha-bubble-arrow + div {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.btn, input.btn {
  display: inline-block;
  padding: 1.8rem 2.4rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.1rem;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
}
.btn--sm, input.btn--sm {
  padding: 1.6rem;
  font-size: 1.2rem;
}
.btn--primary, input.btn--primary {
  border-color: #375773;
  background-color: #375773;
  color: #FFFFFF;
}
.btn--primary:hover, .btn--primary:focus, .btn--primary:active, input.btn--primary:hover, input.btn--primary:focus, input.btn--primary:active {
  border-color: #2f4a62;
  background-color: #2f4a62;
  color: #FFFFFF;
}
.btn--primary.checkout, input.btn--primary.checkout {
  background-color: #375773;
  color: #FFFFFF;
}
.btn--primary.checkout:hover, .btn--primary.checkout:focus, .btn--primary.checkout:active, input.btn--primary.checkout:hover, input.btn--primary.checkout:focus, input.btn--primary.checkout:active {
  border-color: #41a14b;
  background-color: #41a14b;
  color: #FFFFFF;
}
.btn--secondary, .btn--disabled, .btn[disabled], input.btn--disabled, input.btn[disabled], input.btn--secondary {
  background-color: transparent;
  color: #000000;
}
.btn--secondary:hover, .btn--disabled:hover, .btn[disabled]:hover, .btn--secondary:focus, .btn--disabled:focus, .btn[disabled]:focus, .btn--secondary:active, .btn--disabled:active, .btn[disabled]:active, input.btn--secondary:hover, input.btn--secondary:focus, input.btn--secondary:active {
  border-color: #000000;
  background-color: #000000;
  color: #FFFFFF;
}
.btn--subtle--white, input.btn--subtle--white {
  background-color: transparent;
  color: #FFFFFF;
}
.btn--subtle--white:hover, .btn--subtle--white:focus, .btn--subtle--white:active, input.btn--subtle--white:hover, input.btn--subtle--white:focus, input.btn--subtle--white:active {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #2E2E2E;
}
.btn--white, input.btn--white {
  background-color: transparent;
  color: #FFFFFF;
}
.btn--white:hover, .btn--white:focus, .btn--white:active, input.btn--white:hover, input.btn--white:focus, input.btn--white:active {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #2E2E2E;
}
.btn--dark, input.btn--dark {
  background-color: #000000;
  color: #FFFFFF;
}
.btn--dark:hover, .btn--dark:focus, .btn--dark:active, input.btn--dark:hover, input.btn--dark:focus, input.btn--dark:active {
  border-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.btn--pos, input.btn--pos {
  background-color: transparent;
  color: #000000;
}
.btn--pos:hover, .btn--pos:focus, .btn--pos:active, input.btn--pos:hover, input.btn--pos:focus, input.btn--pos:active {
  border-color: #41a14b;
  background-color: #41a14b;
  color: #FFFFFF;
}
.btn--neg, input.btn--neg {
  background-color: transparent;
  color: #000000;
}
.btn--neg:hover, .btn--neg:focus, .btn--neg:active, input.btn--neg:hover, input.btn--neg:focus, input.btn--neg:active {
  border-color: #E41E1D;
  background-color: #E41E1D;
  color: #FFFFFF;
}
.btn--disabled, .btn[disabled], input.btn--disabled, input.btn[disabled] {
  opacity: 0.25;
  pointer-events: none;
}

input.btn {
  width: auto;
}

.dropdown-toggle {
  overflow: hidden;
}

.question {
  color: #fff;
  background-color: #c4c4c4;
  border-radius: 2rem;
  line-height: 1.4rem;
  font-size: 1.2rem;
  position: relative;
}

.icon__wrapper {
  width: 4rem;
  height: 4rem;
  display: flex;
}
.icon__wrapper svg {
  display: block;
  margin: auto;
}
.icon__input, .icon__input--right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.icon__input--right {
  right: 0;
}
.icon__star {
  float: left;
  margin-right: 0.8rem;
}
@media (min-width: 1200px) {
  .icon__star {
    margin-right: 2rem;
  }
}

.general-content__wrapper {
  max-width: 88rem;
}
.general-content__image {
  margin-bottom: 4rem;
}
@media (min-width: 992px) {
  .general-content__image {
    margin-bottom: 8rem;
  }
}
.general-content__button {
  text-align: center;
  margin-top: 4rem;
}
@media (min-width: 992px) {
  .general-content__button {
    margin-top: 8rem;
  }
}

.simple-content__wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .simple-content__wrapper {
    width: calc(66.6666666667% - 4rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .simple-content__wrapper {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}

.review__wrapper {
  background-color: rgba(92, 50, 45, 0.9);
  color: #FFFFFF;
  text-align: center;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
.review__blocks {
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 992px) {
  .review__blocks {
    flex-wrap: wrap;
    flex-direction: initial;
  }
}
.review__block {
  width: 100%;
  padding: 1.6rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding-bottom: 10rem !important;
  position: relative;
  text-align: left;
  margin-bottom: 1.8rem;
}
.review__block:first-child {
  width: 100%;
}
.review__block:not(:first-child) {
  flex: 1;
}
@media (min-width: 768px) {
  .review__block {
    padding: 1.8rem;
  }
}
@media (min-width: 992px) {
  .review__block {
    margin-bottom: 0;
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
    padding: 2.4rem;
  }
}
.review__title {
  width: 100%;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
}
.review__stars__wrapper {
  margin-bottom: 2rem;
}
.review__stars__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.review__name, .review__g {
  position: absolute;
  bottom: 2.5rem;
}
.review__name {
  font-weight: 500;
}
.review__g {
  right: 1.6rem;
}
@media (min-width: 768px) {
  .review__g {
    right: 1.8rem;
  }
}
@media (min-width: 992px) {
  .review__g {
    right: 2.4rem;
  }
}

@media (min-width: 992px) {
  .contact__wrapper {
    display: flex;
    height: inherit;
  }
  .contact__wrapper:last-of-type > div {
    margin-bottom: 0;
  }
}
.contact__left, .contact__right, .contact__telephone, .contact__address {
  width: 100%;
}
@media (min-width: 992px) {
  .contact__left, .contact__right, .contact__telephone, .contact__address {
    width: 50%;
  }
}
.contact__left {
  padding: 0 1.8rem;
}
@media (min-width: 992px) {
  .contact__left {
    padding: 0 2.4rem 0 1.8rem;
  }
}
@media (min-width: 992px) {
  .contact__details {
    display: flex;
  }
}
.contact__overview, .contact__telephone, .contact__address, .contact__hours {
  margin-bottom: 4rem;
}
@media (min-width: 992px) {
  .contact__overview, .contact__telephone, .contact__address, .contact__hours {
    margin-bottom: 5rem;
  }
}

.design__portfolio__btn-wrapper {
  width: 100%;
}
.design__portfolio__btn-wrapper .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .design__portfolio__btn-wrapper .btn {
    width: auto;
  }
}
.design__portfolio__btn-wrapper:last-of-type {
  margin-top: 1.8rem;
}
@media (min-width: 768px) {
  .design__portfolio__btn-wrapper {
    width: calc(50%);
    float: left;
    margin-left: 0;
    display: flex;
  }
  .design__portfolio__btn-wrapper:last-of-type {
    justify-content: flex-end;
    margin-top: 0;
  }
}

.project-grid {
  display: block;
}
@media (min-width: 768px) {
  .project-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
.project-grid-item__wrapper {
  width: 100%;
  margin-bottom: 1.8rem;
  display: block;
}
@media (min-width: 768px) {
  .project-grid-item__wrapper {
    width: calc(50% - 1.8rem);
  }
  .project-grid-item__wrapper + .project-grid-item__wrapper:nth-of-type(even) {
    margin-left: 1.8rem;
  }
}
.project-grid-item__info {
  padding: 4rem;
}
@media (min-width: 768px) {
  .project-grid-item__info {
    padding: 8rem;
  }
}

.studio__overview__wrapper, .studio__images__wrapper {
  width: 100%;
}
@media (min-width: 992px) {
  .studio__overview__wrapper {
    width: calc(41.6666666667% - 3.4rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .studio__images__wrapper {
    width: calc(58.3333333333% - 3.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}

.boxed-content__wrapper {
  display: flex;
  flex-flow: column;
  max-width: 1366px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .boxed-content__wrapper {
    padding: 4.8rem;
    border: 1px solid #423E39;
    border-radius: 4px;
  }
}
@media (min-width: 992px) {
  .boxed-content__row {
    display: flex;
    flex-wrap: wrap;
  }
}
.boxed-content__form__row {
  width: calc(100%);
  float: left;
  margin-left: 0;
  padding-bottom: 2rem;
}
@media (min-width: 992px) {
  .boxed-content__form__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    padding: 1.8rem 0;
  }
}
.boxed-content__form__row .control-label {
  padding-bottom: 1.6rem;
  padding-right: 1.6rem;
  display: block;
}
@media (min-width: 992px) {
  .boxed-content__form__row .control-label {
    width: calc(25%);
    float: left;
    margin-left: 0;
  }
}
.boxed-content__form__row .control-label small {
  display: block;
  font-size: 1.2rem;
  padding-top: 1.6rem;
}
@media (min-width: 992px) {
  .boxed-content__form__row .input__wrapper {
    width: calc(75%);
    float: left;
    margin-left: 0;
  }
}

@-webkit-keyframes navFadeIn {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes navFadeIn {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-ms-keyframes navFadeIn {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes navFadeIn {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes navFadeIn {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
header ul a, header ul > li > span {
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
}

.primary__nav__wrapper {
  max-width: 1644px;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.nav {
  min-height: 100%;
  position: absolute;
  -webkit-overflow-scrolling: touch;
  background-color: #FFFFFF;
  z-index: 10;
  top: 0;
  right: -100%;
  transition: transform 0.25s ease-in-out;
  width: 100%;
}
@media (max-width: 991px) {
  .nav--is-open .nav {
    padding-top: 40px;
    transform: translateX(-100%);
  }
}
.nav li {
  position: relative;
}
@media (min-width: 992px) {
  .nav {
    width: 100%;
    right: auto;
    position: relative;
    overflow: visible;
    border-left: none;
    font-size: 1.2rem;
  }
  .nav a {
    height: 100%;
    display: block;
    transition: all 0.15s ease-in-out;
  }
}
.nav__wrapper {
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 7.2rem;
  width: 100%;
  max-width: 1644px;
  z-index: 999;
}
.admin-bar .nav__wrapper {
  top: 46px;
}
@media (min-width: 782px) {
  .admin-bar .nav__wrapper {
    height: auto;
    top: 32px;
  }
}
@media (min-width: 992px) {
  .nav__wrapper {
    width: auto;
    padding: 0 1.8rem;
    height: auto;
    background-color: #FFFFFF;
    box-shadow: 0 0rem 0rem 0rem #375773;
    transition: box-shadow 0.25s ease-in-out;
  }
  .scrolled .nav__wrapper {
    box-shadow: 0 0 5.6rem -2.4rem;
  }
}
.nav .menu {
  list-style: none;
  margin: 0;
}
@media (min-width: 992px) {
  .nav .menu {
    margin: 0;
    position: initial;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 992px) {
  .nav .menu li.menu-item {
    border-bottom: solid 1px #ECEBEB;
  }
  .nav .menu li.menu-item .sub-menu-item {
    background-color: #ECEBEB;
    border-bottom: solid 1px #dddbdb;
  }
  .nav .menu li.menu-item .sub-menu-item .child-sub-menu-item {
    background-color: #cecccc;
    border-bottom: solid 1px #dddbdb;
  }
}
.nav .menu > li {
  display: block;
}
@media (min-width: 992px) {
  .nav .menu > li {
    float: left;
    border-bottom: none;
  }
}
.nav .menu > li a, .nav .menu > li > span {
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 1rem 1.6rem;
  display: block;
  color: #000000;
  text-decoration: none;
  text-align: left;
  font-weight: 400;
}
@media (min-width: 992px) {
  .nav .menu > li a, .nav .menu > li > span {
    padding: 2.4rem 1.2rem;
    display: block;
  }
}
@media (min-width: 1200px) {
  .nav .menu > li a, .nav .menu > li > span {
    padding: 2.4rem 2rem;
  }
}
@media (min-width: 1200px) {
  .nav .menu > li a, .nav .menu > li > span {
    font-size: 1.6rem;
  }
}
.nav .menu > li:hover, .nav .menu > li:focus {
  color: #000000;
  background-color: #FFFFFF;
}
@media (min-width: 992px) {
  .nav .menu > li:hover, .nav .menu > li:focus {
    color: #000000;
  }
}

@media (min-width: 992px) {
  .menu-item-has-children.nav-main-item:not(.mega-menu):hover > .sub-menu {
    border: 1px solid #423E39;
    border-top: none;
  }
  .menu-item-has-children.nav-main-item:not(.mega-menu):hover > .sub-menu li:hover {
    background-color: #ECEBEB;
  }
  .menu-item-has-children.nav-main-item.mega-menu:hover > .sub-menu li:hover > a > span {
    color: #375773;
  }
}
.menu-item-has-children .menu-item-chevron {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  top: 1.2rem;
  right: 2rem;
  pointer-events: none;
  border: 2px solid;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  transition: transform 0.15s ease-in-out;
}
.menu-item-has-children .menu-heading .menu-item-chevron {
  top: 1.5rem;
}
@media (max-width: 992px) {
  .menu-item-has-children .nav-main-link {
    position: relative;
    padding-right: 6rem;
    width: 100%;
    display: flex;
  }
}
@media (max-width: 992px) {
  .menu-item-has-children a .nav-main-link {
    pointer-events: none;
  }
  .menu-item-has-children a .nav-main-link .menu-item-chevron {
    right: 0.5rem;
    top: 0;
  }
}
.menu-item-has-children .sub-menu {
  min-width: 30rem;
  list-style: none;
  display: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.15s ease-in-out;
  padding: 0;
}
.menu-item-has-children .sub-menu ul {
  background-color: #FFFFFF;
}
.menu-item-has-children .sub-menu-open {
  display: block;
  height: auto;
}
@media (min-width: 992px) {
  .menu-item-has-children .sub-menu {
    position: absolute;
    height: auto;
    opacity: 1;
  }
}
.menu-item-has-children .sub-menu .sub-menu {
  display: none;
  left: 100%;
  top: 0.1rem;
}
.menu-item-has-children .sub-menu .sub-menu.child-sub-menu-open {
  display: block;
}
.menu-item-has-children .sub-menu .sub-menu li {
  width: 100%;
}
.menu-item-has-children .sub-menu .sub-menu li a {
  color: #000000;
}
.menu-item-has-children .sub-menu .sub-menu li:hover, .menu-item-has-children .sub-menu .sub-menu li:focus {
  background-color: #FFFFFF;
}
.menu-item-has-children .sub-menu .sub-menu li:hover a, .menu-item-has-children .sub-menu .sub-menu li:focus a {
  color: #000000;
}
.menu-item-has-children .sub-menu > li {
  width: 100%;
}
.menu-item-has-children .sub-menu > li a {
  border-radius: 0;
  color: #000000;
  padding: 1.8rem 2.4rem;
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .menu-item-has-children .sub-menu > li a {
    padding-right: 1.2rem !important;
    border-bottom: none;
  }
}
.menu-item-has-children .sub-menu > li:hover a, .menu-item-has-children .sub-menu > li:focus a {
  color: #000000;
  border-color: #423e39;
}
@media screen and (min-width: 992px) {
  .menu-item-has-children:hover > .sub-menu {
    display: block;
    flex-direction: row;
  }
}
.menu-item-has-children__logged-in .sub-menu {
  flex-direction: column;
}

.is--open > .menu-item-chevron {
  transform: rotate(135deg);
}

.column--open {
  display: block;
  background-color: #d9d9d9 !important;
}

.column--close {
  display: none;
}

.menu-trigger {
  z-index: 9994;
}
.menu-trigger__wrapper {
  user-select: none;
  cursor: pointer;
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 1.4rem 4.2rem 1.4rem 1.4rem;
  font-weight: normal;
  right: 0;
  top: 0;
}
@media (min-width: 992px) {
  .menu-trigger__wrapper {
    display: none;
  }
}
.menu-trigger__wrapper:hover, .menu-trigger__wrapper:focus {
  color: #375773;
}
.menu-trigger__wrapper:hover .menu-trigger__text, .menu-trigger__wrapper:focus .menu-trigger__text {
  transition: all 0.25s ease-in-out;
  color: #375773;
}
.menu-trigger__wrapper:hover .menu-trigger:before, .menu-trigger__wrapper:hover .menu-trigger__icon:before, .menu-trigger__wrapper:hover .menu-trigger__icon:after, .menu-trigger__wrapper:focus .menu-trigger:before, .menu-trigger__wrapper:focus .menu-trigger__icon:before, .menu-trigger__wrapper:focus .menu-trigger__icon:after {
  background-color: #375773 !important;
}
.menu-trigger:before, .menu-trigger .menu-trigger__icon:before, .menu-trigger .menu-trigger__icon:after {
  background-color: #413E3A;
  content: "";
  position: absolute;
  right: 1.6rem;
  z-index: 0;
  width: 1.6rem;
  height: 2px;
  margin: 0 auto;
  transition: all 0.25s ease-in-out;
}
.menu-trigger:before {
  top: 50%;
  transform: translateY(-100%);
}
.menu-trigger .menu-trigger__icon:before {
  top: calc(50% - 7px);
}
.menu-trigger .menu-trigger__icon:after {
  bottom: calc(50% - 5px);
}
.nav--is-open .menu-trigger:before {
  opacity: 0;
}
.nav--is-open .menu-trigger .menu-trigger__icon:before {
  transform: rotate(45deg) translateY(-50%);
}
.nav--is-open .menu-trigger .menu-trigger__icon:after {
  transform: rotate(-45deg) translateY(-50%);
  right: 15px;
}
.nav--is-open .menu-trigger .menu-trigger__icon:before, .nav--is-open .menu-trigger .menu-trigger__icon:after {
  top: 50%;
  background-color: #375773;
}
.nav--is-open .menu-trigger__text {
  color: #375773 !important;
}

@media (min-width: 992px) {
  .mega-menu-parent {
    position: initial !important;
  }
}
.mega-menu .child-sub-menu .child-sub-menu-open {
  display: block;
}
.mega-menu > .sub-menu--wrapper {
  will-change: height;
  transition: height 0.15s ease-in-out;
  right: 0;
  left: 0;
  background-color: #ECEBEB;
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper {
    padding: 4rem 1.2rem;
  }
}
.mega-menu > .sub-menu--wrapper .sub-menu-list {
  background-color: #ECEBEB;
  position: relative;
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list {
    width: calc(16.6666666667%);
    float: left;
    margin-left: 0;
    padding: 0 1.2rem;
    border-right: solid 1px #707070;
  }
  .mega-menu > .sub-menu--wrapper .sub-menu-list:first-of-type {
    padding-left: 0;
  }
  .mega-menu > .sub-menu--wrapper .sub-menu-list:last-of-type {
    border-right: none;
  }
}
.mega-menu > .sub-menu--wrapper .sub-menu-list .menu-heading {
  text-transform: uppercase;
  text-align: left;
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list .menu-heading {
    padding: 1.2rem;
    font-weight: 900;
    font-size: 1.6rem;
  }
}
.mega-menu > .sub-menu--wrapper .sub-menu-list .menu-heading:hover, .mega-menu > .sub-menu--wrapper .sub-menu-list .menu-heading:focus {
  background-color: transparent;
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list > li {
    position: initial;
  }
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list > li:hover, .mega-menu > .sub-menu--wrapper .sub-menu-list > li:focus, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.sub-menu-open {
    background-color: #FFFFFF;
  }
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list > li a {
    padding: 1.2rem;
    text-transform: initial;
    font-size: 1.6rem;
    font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
    font-family: var(--font-alt);
    font-weight: 300;
  }
}
.mega-menu > .sub-menu--wrapper .sub-menu-list > li.menu-item-has-children > a, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.link-all--items > a {
  position: relative;
  padding-right: 2.4rem;
}
@media (min-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list > li.menu-item-has-children > a:before, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.menu-item-has-children > a:after, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.link-all--items > a:before, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.link-all--items > a:after {
    content: "";
    display: block;
    width: 0.1rem;
    height: 0.8rem;
    background-color: #000000;
    position: absolute;
    right: 1.2rem;
  }
}
.mega-menu > .sub-menu--wrapper .sub-menu-list > li.menu-item-has-children > a:before, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.link-all--items > a:before {
  transform: rotate(-45deg);
  bottom: calc(50% - 3px);
}
.mega-menu > .sub-menu--wrapper .sub-menu-list > li.menu-item-has-children > a:after, .mega-menu > .sub-menu--wrapper .sub-menu-list > li.link-all--items > a:after {
  transform: rotate(45deg);
  top: calc(50% + 0px);
}
@media (max-width: 992px) {
  .mega-menu > .sub-menu--wrapper .sub-menu-list > .sub-menu-item.menu-heading {
    display: block;
  }
}
.mega-menu > .sub-menu--wrapper .menu-item-has-children .sub-menu {
  z-index: 9;
  position: static;
  right: 0;
  left: calc(100% - 1.2rem);
  top: -4rem;
  bottom: -4rem;
  background-color: #FFFFFF;
}
@media (min-width: 992px) {
  .mega-menu:hover > .sub-menu {
    display: flex !important;
  }
  .mega-menu li.link-all--items span {
    font-weight: 900;
    color: #375773;
  }
}

a.page-numbers, span.page-numbers, .dots {
  width: 4.8rem;
  height: 4.8rem;
  transition: all 0.15s ease-in-out;
  padding: 0;
  line-height: 3.5;
  font-size: 1.4rem;
  font-weight: bold;
  color: #000000;
  background-color: #707070;
  display: inline-block;
  text-align: center;
}
a.page-numbers:hover, a.page-numbers:focus, span.page-numbers:hover, span.page-numbers:focus, .dots:hover, .dots:focus {
  background-color: #585858 !important;
  color: #FFFFFF !important;
  transform: translateY(-1.2rem);
}
a.page-numbers.active, a.page-numbers.current, span.page-numbers.active, span.page-numbers.current, .dots.active, .dots.current {
  background-color: #375773;
  color: #FFFFFF;
}

.supernav__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  background: #FFFFFF;
  border-bottom: 1px solid #423E39;
}
@media (min-width: 992px) {
  .supernav__wrapper {
    display: block;
  }
}
.supernav__container {
  font-size: 0;
  margin: 0 auto;
  max-width: 1644px;
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.supernav__links--left {
  width: 60%;
  max-width: 25rem;
  min-width: 20rem;
}
@media (min-width: 992px) {
  .supernav__links--left {
    width: initial;
    margin-left: 2rem;
  }
}
.supernav__links--right {
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
@media (min-width: 992px) {
  .supernav__links--right {
    display: flex;
  }
}
@media (min-width: 992px) {
  .supernav__links--right .account--link--login {
    margin-right: 4rem;
  }
}
.supernav__links--right .account--link--logged-in {
  position: relative;
}
.supernav__links--right .account--link--logged-in .welcome {
  margin-right: 3.5rem;
}
.supernav__links--right .account--link--logged-in .sub-menu {
  display: none;
  position: absolute;
  z-index: 998;
  background: white;
  width: 100%;
  border: 1px solid #423E39;
}
.supernav__links--right .account--link--logged-in .sub-menu li a {
  display: block;
  padding: 1.8rem;
}
.supernav__links--right .account--link--logged-in .sub-menu li a:hover, .supernav__links--right .account--link--logged-in .sub-menu li a:focus {
  background-color: #ECEBEB;
}
.supernav__links--right .account--link--logged-in .account--link--account {
  cursor: default;
  margin-right: 4.5rem;
}
.supernav__links--right .account--link--logged-in .account--link--account:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  bottom: -1px;
  z-index: 999;
  background-color: white;
  opacity: 0;
}
.supernav__links--right .account--link--logged-in:hover .account--link--account {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  background-color: white;
  cursor: default;
  color: #000;
}
.supernav__links--right .account--link--logged-in:hover .account--link--account:after {
  opacity: 1;
}
.supernav__links--right .account--link--logged-in:hover .sub-menu {
  display: block;
  top: calc(100% - 1px);
}
.supernav__links__mobile {
  display: block;
}
@media (min-width: 992px) {
  .supernav__links__mobile {
    display: none !important;
  }
}
.supernav--link {
  background-color: #FFFFFF;
  color: #413E3A;
  display: block;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.supernav--link:hover, .supernav--link:focus, .supernav--link:active {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #413E3A;
}
.supernav--subtle-promo-link {
  text-decoration: underline;
}

.basket__link__wrapper {
  display: flex;
  align-items: center;
  margin: 0 1.8rem;
}
.basket__link__wrapper a {
  position: relative;
}
.basket__counter__wrapper {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #375773;
  top: -8px;
  left: -12px;
}
.basket__counter__fig {
  position: relative;
  display: block;
  margin: auto;
  font-size: 1.6rem;
  font-weight: 900;
  color: #FFFFFF;
}

.footer {
  background: #423E39;
  width: calc(100%);
  float: left;
  margin-left: 0;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.footer h2 {
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
}
.footer__wrapper {
  max-width: 1644px;
  margin: 0 auto;
  padding: 2rem 2rem;
}
.footer__links {
  border-top: 0.06rem solid #FFFFFF;
  border-bottom: 0.06rem solid #FFFFFF;
  color: #FFFFFF;
  margin-bottom: 4rem;
}
.footer__links__row {
  display: flex;
  flex-direction: column;
}
.footer__links__row li {
  text-align: center;
}
@media (min-width: 768px) {
  .footer__links__row {
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer__links p, .footer__links a {
  color: #FFFFFF;
  font-size: 1.6rem;
}
.footer__links a {
  text-decoration: none;
  padding: 1.6rem;
  display: block;
}
@media (min-width: 768px) {
  .footer__links a {
    padding: 2.4rem 1.8rem;
  }
}
.footer__links a:hover {
  color: #423E39;
  background-color: #FFFFFF;
}
.footer__links a:focus {
  color: #423E39;
  background-color: #FFFFFF;
  padding-left: 1rem;
  outline: none;
}
.footer__links__social {
  margin-bottom: 4rem;
}
.footer__links__social ul {
  justify-content: center;
}
.footer__links__social ul.footer__links__row {
  flex-direction: row;
}
.footer__links__social ul li {
  padding: 1.2rem;
}
.footer__links__social ul li a {
  display: block;
  width: 3rem;
  height: 3rem;
  transition: opacity 0.25s ease-in-out;
}
.footer__links__social ul li a:hover {
  opacity: 0.5;
}
.footer__links__social img {
  width: 100%;
  height: auto;
}
.footer__links__heading {
  font-size: 1.4rem;
  margin-bottom: 1.8rem;
  font-weight: 500;
}
.footer__newsletter {
  text-align: center;
}
.footer__newsletter__text {
  color: #FFFFFF;
  max-width: 67rem;
  margin: 0 auto;
}
.footer__newsletter__text h2 {
  margin-bottom: 2rem;
}
.footer__newsletter__text p {
  font-size: 1.8rem;
  line-height: 1.25;
}
@media (min-width: 992px) {
  .footer__newsletter__text p {
    font-size: 2.4rem;
  }
}
.footer__newsletter__form {
  color: #FFFFFF;
}
.footer__legal {
  text-align: center;
  background-color: #423E39;
  color: #FFFFFF;
  padding: 0 0 1.8rem 0;
}
.footer__legal::after {
  clear: both;
  content: "";
  display: block;
}
.footer__legal__copyright {
  display: inline-flex;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}
.footer__legal__copyright pre {
  color: #FFFFFF;
}
.footer__legal__registration {
  margin-bottom: 1.8rem;
}
.footer__legal__registration p {
  font-size: 1.2rem;
}

.pop-up-mask {
  background-color: #000;
  opacity: 0.4;
  z-index: 11;
}

.pop-up-window {
  z-index: 1000;
  display: none;
  overflow: initial;
  margin: auto;
  top: 50%;
  left: 50%;
  background-color: #FFFFFF;
}
.pop-up-window .close-button {
  position: absolute;
  display: block;
  z-index: 2;
  right: 1rem;
  top: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  background: url(/style/images/popup-window-close.png) no-repeat 0 0 transparent;
}
.pop-up-window .circle.ok-button {
  position: relative;
  left: 65%;
  bottom: 1rem;
  padding: 1.2rem 3rem;
}
.pop-up-window .content {
  padding: 10px 13px;
  height: 100%;
  overflow-y: auto;
}
.pop-up-window .content h5 {
  font-size: 16px;
  font-weight: bold;
}
.pop-up-window .help .content span.more {
  display: none;
}
.pop-up-window .messages h3 {
  margin-top: 10px;
}
.pop-up-window .messages ul {
  padding-left: 0px;
  list-style: none;
}

.upload-artwork .circle.confirm-button {
  margin-top: 25px;
}
.upload-artwork .circle.confirm-button .state-text-confirmed {
  display: none;
}
.upload-artwork .circle.confirm-button.state-confirmed {
  margin-top: 25px;
}
.upload-artwork .circle.confirm-button.state-confirmed .state-text-confirmed {
  display: inline;
}
.upload-artwork .circle.confirm-button.state-confirmed .state-text-default {
  display: none;
}
.upload-artwork .content {
  overflow: hidden;
  padding-bottom: 20px;
}
.upload-artwork .content .info-section {
  display: flex;
}
.upload-artwork .content .info-section__description h4 {
  float: left;
  margin-bottom: 5px;
  font-weight: bold;
}
.upload-artwork .content .info-section__description p {
  float: left;
}
.upload-artwork .content .info-section__description .question {
  padding: 0.4rem 0.8rem;
  box-shadow: none;
  bottom: 0.35rem;
}
.upload-artwork .content .info-section .browse-for-file {
  position: relative;
  margin-left: 0.5rem;
}
.upload-artwork .content .info-section .browse-for-file input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0.01;
  height: 100%;
}

.editorModal {
  background: #FFF;
  border: 1px solid #AAAAAA;
  padding: 0.2rem;
}
.editorModal .header {
  height: 4rem;
  margin: 0 0.2rem 0.2rem 0;
}
.editorModal .header .title {
  float: left;
  font-weight: bold;
  font-size: 1.9rem;
  padding: 0.6rem 1.5rem;
  margin: 0;
}
.editorModal .header .close {
  background: url(/style/images/popup-window-close.png) no-repeat;
  margin: 0.7rem 0.7rem 0 0;
  float: right;
  width: 2.1rem;
  height: 2rem;
}
.editorModal .orderBar {
  height: 5rem;
}
.editorModal .orderBar .filenameLabel, .editorModal .orderBar .categoryLabel {
  margin: 1.9rem 0 1rem 1rem;
  font-size: 1.4rem;
  float: left;
}
.editorModal .orderBar .filenameInput, .editorModal .orderBar select {
  color: #000;
  border: none;
  box-shadow: 0 0 0.6rem 0.4rem #dbdbdb;
  float: left;
  border: none;
  outline: none;
  padding: 0.6rem 1.2rem;
  width: 31rem;
  height: 3.4rem;
  color: #000;
  border-radius: 0.4rem;
  margin: 0 0 2rem 0;
  margin-bottom: 1.5rem;
}
.editorModal .orderBar input[type=text], .editorModal .orderBar select {
  margin-top: 0.9rem;
  margin-left: 1.2rem;
}
.editorModal .orderBar .btn {
  float: right;
  margin: 0.5rem 0.5rem;
}

.grid__blocks::after {
  clear: both;
  content: "";
  display: block;
}
.grid__title {
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .grid__title {
    padding-bottom: 8rem;
  }
}
.grid__block, .account__content__wrapper .grid__block {
  text-align: center;
  padding-bottom: 2rem;
  width: calc(100%);
  float: left;
  margin-left: 0;
}
@media (min-width: 580px) {
  .grid__block, .account__content__wrapper .grid__block {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .grid__block, .account__content__wrapper .grid__block {
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.grid__block:last-child::after {
  clear: both;
  content: "";
  display: block;
}
.grid__block__img {
  margin-bottom: 2.6rem;
}
.grid__block__heading {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .grid__block__heading {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
}
.grid__block__txt p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .grid__block__txt p {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
}
.grid__block__link {
  font-weight: 600;
  color: #375773;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  margin-bottom: 1.8rem;
  display: block;
}
.grid__block__btn-wrapper {
  text-align: center;
  padding: 1.8rem 0;
}

.banner {
  display: flex;
}
.banner__wrapper {
  width: 100%;
  background-color: #5C322D;
}
@media (min-width: 0) and (max-width: 992px) {
  .banner {
    flex-wrap: wrap;
  }
}
.banner__item {
  margin: auto;
  flex-direction: column;
  text-align: center;
  width: 50%;
  padding: 1.6rem;
}
@media (min-width: 992px) {
  .banner__item {
    width: auto;
    padding: 2rem;
  }
}
.banner__image {
  margin: 0 auto 1.8rem;
  display: flex;
  width: 7rem;
  height: 7rem;
}
@media (min-width: 992px) {
  .banner__image {
    width: 9.6rem;
    height: 9.6rem;
  }
}
.banner__title {
  color: #FFFFFF;
  font-size: 1.2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
@media (min-width: 768px) {
  .banner__title {
    font-size: 1.8rem;
  }
}

.specification-panel {
  border: 1px solid #423E39;
  border-radius: 4px;
  width: 100%;
}
@media (min-width: 0) and (max-width: 992px) {
  .specification-panel {
    margin: 0 auto;
  }
}
.specification-panel .inner .specification-component h4 {
  text-align: center;
  color: #413E3A;
}
.specification-panel .inner .specification-component .input-wrapper .row {
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
  padding: 1rem 4rem;
}
.specification-panel .inner .specification-component .input-wrapper .row .col-sm-4, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-5, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-6, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-7, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-12 {
  padding: 0;
}
.specification-panel .inner .specification-component .input-wrapper .row .col-sm-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.specification-panel .inner .specification-component .input-wrapper .row .col-sm-7 button span, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-7 button li, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-7 ul span, .specification-panel .inner .specification-component .input-wrapper .row .col-sm-7 ul li {
  font-size: 2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
.specification-panel .inner .specification-component .input-wrapper .row #spec-title-label {
  display: block;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
}
.specification-panel .inner .specification-component .input-wrapper .row .spec-label {
  display: block;
  text-align: center;
  padding-right: 2em;
}
.specification-panel .inner .specification-component .input-wrapper .row p {
  color: #413E3A;
  font-weight: 300;
  font-size: 11px;
  font-size: 0.6875rem;
  margin: 0;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown {
  display: inline-block !important;
  width: inherit;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown .dropdown-toggle {
  min-height: 48px;
  width: inherit;
  background-color: #FFFFFF;
  border: 1px solid #423E39;
  border-radius: 4px;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown .dropdown-toggle::after {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 1.5rem;
  top: 2rem;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown .dropdown-menu {
  width: 100%;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown .dropdown-menu li a {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.specification-panel .inner .specification-component .input-wrapper .row .dropdown .dropdown#ddlFormat .dropdown-menu {
  min-width: 215px !important;
}
.specification-panel .inner .specification-component .input-wrapper .row .one-option .dropdown-toggle {
  cursor: default;
}
.specification-panel .inner .specification-component .input-wrapper .row .one-option .dropdown-toggle::after {
  display: none;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput input {
  display: block;
  width: 100%;
  min-height: 48px;
  text-align: center;
  border: none;
  padding: 0 0.3em;
  border-radius: 15px;
  line-height: 1em;
  background-color: transparent;
  color: #413E3A;
  line-height: 20px;
  overflow: hidden;
  margin-bottom: 4px;
  font-size: 2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
  border: 1px solid #423E39;
  border-radius: 4px;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput input:focus {
  outline: none;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple {
  display: block;
  width: 100%;
  height: 20px;
  text-align: center;
  border: none;
  padding: 0 0.3em;
  border-radius: 15px;
  line-height: 1em;
  background-color: transparent;
  color: #413E3A;
  line-height: 20px;
  font-size: 12px;
  font-size: 0.75rem;
  overflow: hidden;
  margin-bottom: 4px;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple:focus {
  outline: none;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple input {
  box-shadow: none;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions input,
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions span {
  float: left;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions input {
  width: 45%;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions input:first-of-type {
  text-align: right;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions input:last-of-type {
  text-align: left;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .dimensions span {
  width: 10%;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput .multiple .label-only-view {
  box-shadow: none;
}
.specification-panel .inner .specification-component .input-wrapper .row .textInput label {
  display: block;
  text-align: center;
  padding-right: 20px;
  font-weight: normal;
}
.specification-panel .inner .specification-component .input-wrapper .row .locations {
  display: block;
  float: left;
  margin-top: 10px;
}
.specification-panel .inner .specification-component .input-wrapper .row .locations input {
  width: 15px !important;
  height: 15px;
  opacity: 1;
  float: left;
  width: initial;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: static;
  top: auto;
  left: auto;
}
.specification-panel .inner .specification-component .input-wrapper .row .locations span {
  float: left;
  margin-left: 5px;
  margin-right: 15px;
  color: #808080;
  font-size: 0.75rem;
}
.specification-panel .inner .specification-component .input-wrapper .row .locations a {
  float: left;
}
.specification-panel .inner .specification-component .input-wrapper .row .locations a:first-of-type {
  margin-right: 20px;
}
.specification-panel .inner .specification-component .input-wrapper .row .divider {
  width: 100%;
  height: 25px;
  color: #808080;
  position: relative;
  margin-bottom: 5px;
}
.specification-panel .inner .specification-component .input-wrapper .row .divider:before, .specification-panel .inner .specification-component .input-wrapper .row .divider:after {
  content: "";
}
.specification-panel .inner .specification-component .input-wrapper .row .divider .divider-line,
.specification-panel .inner .specification-component .input-wrapper .row .divider .divider-label {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.specification-panel .inner .specification-component .input-wrapper .row .divider .divider-line {
  height: 10px;
  border-bottom: 1px solid #808080;
}
.specification-panel .inner .specification-component .input-wrapper .row .divider .divider-label {
  text-align: center;
}
.specification-panel .inner .specification-component .input-wrapper .row .divider .divider-label span {
  background-color: #FFF;
  padding: 0 5px;
  font-weight: bold;
  font-size: 0.75rem;
}
.specification-panel .inner .specification-component .input-wrapper .row .turnaround-cost-wrapper, .specification-panel .inner .specification-component .input-wrapper .row .turnaround-time-wrapper {
  display: none;
}
.specification-panel .inner .specification-component .input-wrapper #delivery-row {
  padding-top: 3rem;
}
.specification-panel .inner .specification-component .input-wrapper #delivery-row div {
  border-bottom: 1px solid #413E3A;
  padding-bottom: 1.5rem;
}
.specification-panel .inner .specification-component .input-wrapper #delivery-row div p {
  font-size: 2rem;
}
.specification-panel .inner .specification-component .input-wrapper #cost-row {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1.5rem;
  color: #375773;
}
.specification-panel .inner .specification-component .input-wrapper #cost-row label {
  font-weight: 700;
  font-size: 2.4rem;
}
.specification-panel .inner .specification-component .input-wrapper #cost-row #lblCost,
.specification-panel .inner .specification-component .input-wrapper #cost-row #lblError {
  text-align: right;
  display: none;
  font-weight: 700;
  font-size: 2.4rem;
}
.specification-panel .inner .specification-component .input-wrapper #cost-row #lblError {
  color: #bf0500;
  font-size: 14px;
}
.specification-panel .inner .specification-component .input-wrapper #pricing-preferences {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1rem;
}
.specification-panel .inner .specification-component .input-wrapper #pricing-preferences .pricing-preference {
  color: #585858;
  font-size: 1.2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper {
  background-color: #ECEBEB;
  color: #585858;
  display: none;
  margin-top: 1.7rem;
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times {
  justify-content: space-between;
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-time-wrapper .turnaround-time-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-time-wrapper .turnaround-cost-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-cost-wrapper .turnaround-time-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-cost-wrapper .turnaround-cost-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-time-wrapper .turnaround-time-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-time-wrapper .turnaround-cost-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-cost-wrapper .turnaround-time-label, .specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-cost-wrapper .turnaround-cost-label {
  margin-right: 0.4rem;
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-cost-wrapper {
  font-size: 1.6rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-options .turnaround-cost-wrapper .turnaround-cost-label {
  font-size: 1.6rem;
  text-transform: capitalize;
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-time-wrapper {
  font-size: 1.2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.specification-panel .inner .specification-component .input-wrapper .turnaround-wrapper #turnaround-times .turnaround-time-wrapper .turnaround-time-label {
  font-size: 1.2rem;
}
.specification-panel .inner .specification-component .order-button {
  text-align: center;
  margin-top: 4rem;
}
.specification-panel .inner .specification-component .order-button .btn {
  width: 86%;
  margin: 0 7%;
}
.specification-panel .loaded .inner .specification-component .input-wrapper {
  visibility: visible;
  opacity: 1;
}

.pop-up-window.upload-artwork .uploading-indicator {
  display: none;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress {
  margin: auto;
  width: 120px;
  height: 120px;
  background-color: #d6dadc;
  border-radius: 50%;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .radial-progress-mask, .pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .fill, .pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .shadow {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .shadow {
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2) inset;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .radial-progress-mask, .pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .fill {
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 1s;
  transition: -ms-transform 1s;
  transition: transform 1s;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .radial-progress-mask {
  clip: rect(0px, 120px, 120px, 60px);
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .radial-progress-circle .radial-progress-mask .fill {
  clip: rect(0px, 60px, 120px, 0px);
  background-color: #809db7;
}
.pop-up-window.upload-artwork .uploading-indicator .radial-progress .inset {
  width: 90px;
  height: 90px;
  position: absolute;
  margin-left: 15px;
  margin-top: 15px;
  background-color: #fbfbfb;
  border-radius: 50%;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
}

.spec-panel-heading {
  font-size: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  padding: 4rem;
  margin-bottom: 0;
}

.form-control.address-dropdown {
  font-family: var(--font-alt);
  padding: 0 2.4rem;
  width: 49%;
  border-radius: 4px;
}
@media (min-width: 0) and (max-width: 992px) {
  .form-control.address-dropdown {
    width: 100%;
  }
}

#product-wrapper label {
  font-size: 2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
#product-wrapper li {
  font-size: 2rem;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
#product-wrapper #content-area .view-specification {
  display: flex;
  justify-content: space-evenly;
  margin: 7.5rem 0;
}
@media (min-width: 0) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification {
    flex-direction: column-reverse;
  }
}
#product-wrapper #content-area .view-specification #leftCol {
  width: 40%;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #product-wrapper #content-area .view-specification #leftCol {
    width: 46%;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification #leftCol {
    width: 90%;
    margin: 0 auto;
  }
}
#product-wrapper #content-area .view-specification #leftCol .product-content--title {
  display: flex;
  position: relative;
  right: 6rem;
  align-items: center;
  margin-bottom: 1rem;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--title h1, #product-wrapper #content-area .view-specification #leftCol .product-content--title h2, #product-wrapper #content-area .view-specification #leftCol .product-content--title h3, #product-wrapper #content-area .view-specification #leftCol .product-content--title h4 {
  margin-bottom: 0;
}
@media (min-width: 0) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--title {
    flex-direction: column;
    position: initial;
  }
}
#product-wrapper #content-area .view-specification #leftCol .product-content--title img {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
}
@media (min-width: 0) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--title img {
    margin-bottom: 2rem;
  }
}
#product-wrapper #content-area .view-specification #leftCol .product-content--title h2 {
  margin-bottom: 2rem;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--description + .product-content--title {
  margin-top: 4rem;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--description p a {
  padding: 0;
  color: #375773;
  font-weight: 700;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--description ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.6rem;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--description ul li {
  flex-basis: 50%;
  margin-bottom: 0.6rem;
  list-style-type: disc;
  list-style-position: inside;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--description ul li {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--description ul li {
    flex-basis: 50%;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--description ul li {
    flex-basis: 100%;
  }
}
#product-wrapper #content-area .view-specification #leftCol .product-content--image-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
@media (min-width: 0) and (max-width: 480px) {
  #product-wrapper #content-area .view-specification #leftCol .product-content--image-list {
    flex-direction: column;
  }
}
#product-wrapper #content-area .view-specification #leftCol .product-content--image-list ul li:first-of-type {
  margin-bottom: 1rem;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--image-list ul li {
  margin-bottom: 2.6rem;
  display: flex;
  align-items: center;
}
#product-wrapper #content-area .view-specification #leftCol .product-content--image-list ul li img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
#product-wrapper #content-area .view-specification #rightCol {
  width: 30%;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #product-wrapper #content-area .view-specification #rightCol {
    width: 40%;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  #product-wrapper #content-area .view-specification #rightCol {
    width: 90%;
    margin: 0 auto 4rem auto;
  }
}

.login__content__wrapper {
  text-align: center;
}
.login__form__wrapper {
  max-width: 666px;
  margin: 0 auto;
  text-align: left;
}

.forgot-pass {
  margin-bottom: 1rem;
  display: inline-block;
  color: #375773;
  text-decoration: underline;
}

.list-section--flex {
  flex-flow: column;
}
@media (min-width: 768px) {
  .list-section--flex {
    flex-flow: wrap;
    justify-content: space-between;
  }
}
.list-section__list {
  margin-bottom: 4rem;
  padding: 0 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  .list-section__list {
    width: 33.333%;
    float: left;
  }
}
@media (min-width: 992px) {
  .list-section__list {
    width: 25%;
    float: left;
  }
}
.list-section__item {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 0.25em;
}
@media (min-width: 992px) {
  .list-section__item {
    font-size: 2.6rem;
  }
}
.list-section__item a:hover {
  color: #375773;
}
.list-section__item:first-of-type {
  font-size: 2.4rem;
  margin-bottom: 0.5em;
  color: #375773;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
@media (min-width: 992px) {
  .list-section__item:first-of-type {
    font-size: 4rem;
  }
}

.team__member {
  text-align: center;
  width: calc(100% - 4.8rem);
  float: left;
  margin-left: 2.4rem;
}
@media (min-width: 580px) {
  .team__member {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 768px) {
  .team__member {
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .team__member {
    width: calc(25% - 3rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 1200px) {
  .team__member {
    width: calc(16.6666666667% - 2.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.team__member__image {
  margin-bottom: 1.6rem;
}
.team__member__name {
  text-transform: uppercase;
  margin-bottom: 0.4rem;
  font-weight: 500;
  font-size: 2.4rem;
}
.team__member__title {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  margin-bottom: 1.6rem;
  font-size: 2rem;
}

@media (min-width: 1690px) {
  .order__content__wrapper {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
}
.order__overview__wrapper, .order__address__wrapper {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  margin-bottom: 6rem;
}
.order__overview__wrapper::after, .order__address__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.order__overview__row, .order__address__row {
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  width: calc(100% + 4.8rem);
  margin-bottom: 1.8rem;
}
.order__overview__column, .order__address__column {
  width: calc(100% - 4.8rem);
  float: left;
  margin-left: 2.4rem;
}
@media (min-width: 480px) {
  .order__overview__column, .order__address__column {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 768px) {
  .order__overview__column, .order__address__column {
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .order__overview__column, .order__address__column {
    width: calc(16.6666666667% - 2.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.order__overview__title, .order__address__title {
  font-weight: 400;
}
.order__overview__data, .order__address__data {
  font-weight: 300;
}
.order__address__wrapper {
  width: 100%;
}
@media (min-width: 992px) {
  .order__address__wrapper {
    width: 50%;
    float: left;
  }
}
.order__address__heading {
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
}
.order-table {
  font-weight: 300;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.order-table__actions {
  display: flex;
  align-items: flex-end;
  padding: 1.2rem 1.2rem 0;
}
@media (min-width: 0) and (max-width: 992px) {
  .order-table__actions {
    padding-bottom: 1.6rem;
    padding-left: 0;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  .order-table__column {
    padding-bottom: 1.6rem;
  }
}
@media (min-width: 992px) {
  .order-table__column {
    padding-right: 1.6rem;
  }
}
.order-table__column-title {
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}
.order-table__item {
  width: 100%;
  border-bottom: 1px solid #423E39;
}
.order-table__item:last-of-type {
  margin-bottom: 1.8rem;
}
.order-table__item::after {
  clear: both;
  content: "";
  display: block;
}
@media (min-width: 992px) {
  .order-table__item {
    display: flex;
    padding: 4rem 0 4rem 0;
  }
  .order-table__item__product {
    flex: 6;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  .order-table__item__vat, .order-table__item__price {
    width: 50%;
    text-align: right;
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .order-table__item {
    display: inline-block;
    padding: 3.2rem 0 3.2rem 0;
  }
  .order-table__item__product {
    width: 100%;
  }
  .order-table__item__delete {
    width: 100%;
    align-items: initial;
    text-align: center;
  }
  .order-table__item__date, .order-table__item__vat, .order-table__item__price {
    margin-top: 1.6rem;
  }
  .order-table__item__vat, .order-table__item__price {
    width: calc(25% - 3rem);
    float: left;
    margin-left: 2.4rem;
    text-align: right;
    float: left;
    margin-bottom: 3.2rem;
  }
  .order-table__item__vat {
    margin-left: calc(50% - 3.6rem + 4.8rem);
  }
  .order-table__item__price {
    margin-left: calc(0% - 2.4rem + 4.8rem);
  }
}
.order-table__item__product__title {
  font-size: 2rem;
}
@media (min-width: 992px) {
  .order-table__item__product__title {
    font-size: 2.4rem;
  }
}
.order-table__item__product__description {
  font-size: 1.6rem;
  margin-top: 2.4rem;
}
@media (min-width: 992px) {
  .order-table__item__product__description {
    font-size: 2.4rem;
  }
}
.order-table__item__product__description .product__description-title {
  font-weight: 500;
}
.order-table__item__delete, .order-table__item__date, .order-table__item__vat, .order-table__item__price {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .order-table__item__delete, .order-table__item__date, .order-table__item__vat, .order-table__item__price {
    flex: 1;
  }
}
.order-table__item__vat, .order-table__item__price {
  font-weight: 500;
  font-size: 2rem;
}
.order-table__item__date {
  font-size: 1.6rem;
}
@media (min-width: 992px) {
  .order-table__item__date, .order-table__item__vat, .order-table__item__price {
    justify-content: space-between;
    text-align: right;
    font-size: 2rem;
  }
}
.order-table__item__date__title, .order-table__item__vat__title, .order-table__item__price__title {
  font-size: 1.6rem;
}
@media (min-width: 992px) {
  .order-table__item__date__title, .order-table__item__vat__title, .order-table__item__price__title {
    font-size: 2.4rem;
  }
}
.order-table__item__delete {
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .order-table__item__delete {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.order-table__item__delete .delete.btn {
  padding: 1.2rem 2.4rem 1.2rem 2.4rem;
}
@media (min-width: 992px) {
  .order-table__item:not(:first-child) .order-table__item__date__title, .order-table__item:not(:first-child) .order-table__item__vat__title, .order-table__item:not(:first-child) .order-table__item__price__title {
    display: none;
    padding-top: 0;
  }
  .order-table__item:not(:first-child) .order-table__item__date, .order-table__item:not(:first-child) .order-table__item__vat, .order-table__item:not(:first-child) .order-table__item__price {
    justify-content: flex-end;
  }
}
.order-table-summary {
  width: 100%;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
@media (min-width: 480px) {
  .order-table-summary {
    width: 60vw;
  }
}
@media (min-width: 768px) {
  .order-table-summary {
    width: 40vw;
  }
}
@media (min-width: 992px) {
  .order-table-summary {
    width: 35vw;
  }
}
@media (min-width: 1200px) {
  .order-table-summary {
    width: 25vw;
  }
}
#checkout-content .order-table-summary {
  width: 100%;
}
.order-wrapper {
  max-width: 1644px;
  width: 100%;
}
@media (min-width: 992px) {
  .order-wrapper {
    border: 1px solid #423E39;
    border-radius: 4px;
    margin: 0 1rem 0 1rem;
    padding: 4rem;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  .order-wrapper {
    padding: 2.3rem 3.6rem 2.3rem 3.6rem;
  }
}

.account-container::after {
  clear: both;
  content: "";
  display: block;
}
.account__sidebar__wrapper {
  margin-bottom: 2.4rem;
  width: calc(100%);
  float: left;
  margin-left: 0;
}
@media (min-width: 992px) {
  .account__sidebar__wrapper {
    width: calc(16.6666666667% - 2.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.account__sidebar__wrapper .list--active {
  display: block;
}
.account__sidebar__wrapper .list--active li a.selected {
  background-color: #ECEBEB;
}
.account__menu__trigger {
  display: block;
}
@media (min-width: 992px) {
  .account__menu__trigger {
    display: none;
  }
}
.account__side__menu {
  margin: 1.8rem auto;
  font-size: 1.6rem;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
  border-top: 1px solid #423E39;
  border-color: rgba(65, 62, 58, 0.2);
}
@media (min-width: 992px) {
  .account__side__menu {
    margin: auto;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  .account__side__menu {
    display: none;
  }
}
.account__side__menu li ul {
  display: none;
}
.account__side__menu li ul a {
  padding-left: 4rem;
}
.account__side__menu li {
  position: relative;
  overflow: hidden;
}
.account__side__menu li a {
  display: block;
  position: relative;
  padding: 1.8rem;
  text-transform: uppercase;
  font-family: "ComfortaaVariable", Helvetica, Arial, sans-serif;
  font-family: var(--font-main);
  border-bottom: 1px solid #423E39;
  border-color: rgba(65, 62, 58, 0.2);
}
.account__side__menu li a span {
  position: relative;
  z-index: 2;
}
.account__side__menu li a:before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 100%;
  background-color: #ECEBEB;
  z-index: 1;
  transition: right 0.25s ease-in-out;
}
.account__side__menu li a:hover:before {
  right: 0;
}
.account__content__wrapper {
  width: calc(100%);
  float: left;
  margin-left: 0;
}
.account__content__wrapper .section--padx2 {
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .account__content__wrapper {
    width: calc(83.3333333333% - 4.4rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 1200px) {
  .account__content__wrapper {
    width: calc(75% - 4.2rem);
    float: left;
    margin-left: 2.4rem;
    margin-left: calc(8.3333333333% - 2.6rem + 4.8rem);
  }
}
@media (min-width: 992px) {
  .account__content__wrapper .grid__block {
    width: calc(45.8333333333% - 3.5rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 580px) and (max-width: 992px) {
  .account__content__wrapper .grid__block {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 0) and (max-width: 580px) {
  .account__content__wrapper .grid__block {
    width: calc(100% - 4.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.account__content__heading {
  font-size: 1.2rem;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .account__content__heading {
    width: calc(100% - 4.8rem);
    float: left;
    margin-left: 2.4rem;
    display: block;
  }
}
.account__content__intro {
  max-width: 65ch;
  padding-bottom: 2.4rem;
}
.account__content__intro p {
  min-height: 5.4rem;
}
@media (min-width: 992px) {
  .account__content__intro {
    margin-left: 2.4rem;
  }
}
.account__artwork__lists__wrapper {
  display: flex;
}
.account__artwork__column {
  margin-bottom: 4rem;
  width: calc(100%);
  float: left;
  margin-left: 0;
}
@media (min-width: 992px) {
  .account__artwork__column {
    margin-bottom: 8rem;
    width: calc(50%);
    float: left;
    margin-left: 0;
  }
  .account__artwork__column:first-of-type {
    padding-right: 2rem;
  }
}
.account__artwork__column .artwork__wrapper {
  margin-bottom: 2.4rem;
}
.account__artwork__column .artwork__intro {
  width: calc(100%);
  float: left;
  margin-left: 0;
}
.account__artwork__column .artwork__intro__wrapper {
  margin-bottom: 2.4rem;
}
.account__artwork__column .artwork__intro__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
@media (min-width: 992px) {
  .account__artwork__column .artwork__intro__wrapper {
    display: flex;
    align-items: flex-end;
    padding-right: 1.6rem;
  }
}
@media (min-width: 992px) {
  .account__artwork__column .artwork__intro {
    width: calc(75%);
    float: left;
    margin-left: 0;
  }
}
.account__artwork__column .artwork-toggle__wrapper {
  display: flex;
  flex-flow: row;
  border: 1px solid #423E39;
  border-radius: 4px;
  max-width: 10rem;
}
.account__artwork__column .artwork-toggle__icon__wrapper {
  display: flex;
  padding: 0.4rem;
}
.account__artwork__column .artwork-toggle__icon__wrapper:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #423E39;
}
.account__artwork__column .artwork-toggle__icon__wrapper:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.account__artwork__column .artwork-toggle__icon__wrapper.is--active {
  background-color: #ECEBEB;
}
.account__artwork__column .artwork-toggle__icon__wrapper svg {
  width: 100%;
  height: 100%;
  max-width: 5rem;
  max-height: 2.6rem;
}
.account__artwork__column .artwork__image__wrapper {
  display: none;
}
.account__artwork__column .artwork__image__wrapper img {
  display: none;
}
.account__artwork__column .artwork__image__wrapper.image--active {
  display: block;
  border: 1px solid #423E39;
  border-color: rgba(88, 88, 88, 0.2);
  margin-bottom: 1.6rem;
}
.account__artwork__column .artwork__image__wrapper.image--active img {
  display: block;
}
.account__artwork__column .artwork__name {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
.account-orders__col, .account-orders__col__link, .account-orders__col__desc {
  width: calc(25%);
  float: left;
  margin-left: 0;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}
@media (min-width: 992px) {
  .account-orders__col, .account-orders__col__link, .account-orders__col__desc {
    font-size: 1.6rem;
    width: calc(12.5% - 2.7rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.account-orders__col__desc {
  width: calc(75%);
  float: left;
  margin-left: 0;
}
@media (max-width: 992px) {
  .account-orders__col__desc {
    margin-left: 25%;
  }
}
@media (min-width: 992px) {
  .account-orders__col__desc {
    width: calc(50% - 3.6rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (max-width: 992px) {
  .account-orders__col__link {
    margin-left: 25%;
  }
}
.account-orders__col-heads__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.account-orders__col-head {
  font-size: 1.2rem;
  margin-bottom: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
}
@media (min-width: 992px) {
  .account-orders__col-head {
    font-weight: 800;
  }
}
@media (max-width: 992px) {
  .account-orders__col-head.account-orders__col__desc {
    display: none;
  }
}
.account-orders__item {
  margin-bottom: 2.6rem;
}
.account-orders__item::after {
  clear: both;
  content: "";
  display: block;
}
.account-orders__link {
  color: #375773;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  text-decoration: underline;
}
.account-user__details-form {
  width: calc(100%);
  float: left;
  margin-left: 0;
}
.account-user__details-form__wrapper {
  width: calc(100%);
  float: left;
  margin-left: 0;
}
@media (min-width: 992px) {
  .account-user__details-form__wrapper {
    width: calc(50%);
    float: left;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .account-user__details-form {
    width: calc(100% - 4.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}

.artwork-library__headings {
  margin-bottom: 1.8rem;
}
.artwork-library__headings::after {
  clear: both;
  content: "";
  display: block;
}
.artwork-library__col, .artwork-library__col--action, .artwork-library__col--type, .artwork-library__col--date, .artwork-library__col--name {
  width: calc(100%);
  float: left;
  margin-left: 0;
  margin-bottom: 1.2rem;
}
@media (min-width: 992px) {
  .artwork-library__col, .artwork-library__col--action, .artwork-library__col--type, .artwork-library__col--date, .artwork-library__col--name {
    margin-bottom: initial;
    width: calc(12.5% - 2.7rem);
    float: left;
    margin-left: 2.4rem;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .artwork-library__col--name {
    width: calc(16.6666666667% - 2.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (max-width: 992px) {
  .artwork-library__col--date {
    width: calc(50%);
    float: left;
    margin-left: 0;
    padding-right: 2.4rem;
  }
}
@media (max-width: 992px) {
  .artwork-library__col--type {
    width: calc(50%);
    float: left;
    margin-left: 0;
  }
}
.artwork-library__items__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.artwork-library__item__wrapper {
  margin-bottom: 1.8rem;
}
@media (min-width: 992px) {
  .artwork-library__item__wrapper {
    display: flex;
  }
}
.artwork-library__item__wrapper::after {
  clear: both;
  content: "";
  display: block;
}
.artwork-library__item__wrapper .btn {
  display: flex;
  width: 100%;
  justify-content: center;
}
.artwork-library__mini-heading {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .artwork-library__mini-heading {
    display: none;
  }
}

#basket-content, .checkout-basket__content {
  display: flex;
  justify-content: center;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
  font-weight: 300;
}
@media (min-width: 992px) {
  #basket-content, .checkout-basket__content {
    margin: 8rem 0 8rem 0;
  }
}
.checkout-basket__content .order-wrapper {
  border: none;
  padding: 0;
}
@media (min-width: 992px) {
  .checkout-basket__content .order-wrapper .order-table__item__product__description {
    font-size: 2rem;
  }
}

.summary__container {
  width: auto;
  margin: auto;
  right: 3rem;
}
@media (min-width: 992px) {
  .summary__container {
    position: fixed;
    width: calc(33.3333333333% - 3.2rem);
    float: left;
    margin-left: 2.4rem;
  }
}

.order-table-summary-wrapper {
  display: flex;
}
#checkout-content .order-table-summary-wrapper {
  padding: 1.8rem;
  border: 1px solid #423E39;
  border-radius: 4px;
}
@media (min-width: 992px) {
  .order-table-summary-wrapper {
    justify-content: flex-end;
  }
}
@media (min-width: 0) and (max-width: 992px) {
  .order-table-summary-wrapper {
    justify-content: center;
  }
}
.order-table-summary-wrapper .form-group {
  width: 100%;
  float: none;
  margin: 0 auto;
}
.order-table-summary-wrapper .btn {
  width: 100%;
}
#basket-content .order-table-summary {
  padding: 1.8rem;
  border: 1px solid #423E39;
  border-radius: 4px;
}
.order-table-summary__totals__sub, .order-table-summary__totals__delivery, .order-table-summary__totals__vat, .order-table-summary__totals__total, .order-table-summary__totals__coupon {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  line-height: 2;
}
.order-table-summary__totals__coupon .btn.btn--secondary, .order-table-summary__totals__coupon .btn.btn--disabled, .order-table-summary__totals__coupon .btn[disabled] {
  width: initial;
  padding: 0.4rem 0.4rem;
  line-height: 1.4rem;
}
.order-table-summary__totals__sub, .order-table-summary__totals__total {
  color: #375773;
  font-weight: 500;
}
.order-table-summary__proceed {
  font-size: 1.6rem;
}
.order-table-summary__proceed .btn {
  padding: 2.4rem 4rem 2.4rem 4rem;
  text-align: center;
  width: 100%;
}

.couponWrapper {
  margin: 1.6rem 0 2.6rem 0;
  overflow: auto;
}
@media (min-width: 992px) {
  .couponWrapper {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    width: calc(100% + 4.8rem);
  }
}
.couponWrapper .form-group .coupon-label {
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .couponWrapper .form-group .coupon-label {
    width: calc(100% - 4.8rem);
    float: left;
    margin-left: 2.4rem;
  }
}
@media (min-width: 992px) {
  .couponWrapper .form-group::after {
    clear: both;
    content: "";
    display: block;
  }
}
@media (min-width: 992px) {
  .couponWrapper .form-group .form-control {
    width: calc(58.3333333333% - 3.8rem);
    float: left;
    margin-left: 2.4rem;
    margin-bottom: 0;
  }
}
.couponWrapper .form-group .btn {
  height: 5rem;
  width: 100%;
}
@media (min-width: 992px) {
  .couponWrapper .form-group .btn {
    width: calc(41.6666666667% - 3.4rem);
    float: left;
    margin-left: 2.4rem;
  }
}

#checkout-content {
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}

@media (min-width: 992px) {
  .order__wrapper {
    width: 100%;
  }

  .order-table-summary-wrapper {
    background-color: #FFFFFF;
  }
  .checkout-content__wrapper .order-table-summary-wrapper {
    width: calc(25% - 3rem);
    float: left;
    margin-left: 2.4rem;
    margin-left: calc(8.3333333333% - 2.6rem + 4.8rem);
  }
}
#payment-spinner {
  display: none;
  position: fixed;
  z-index: 999;
  overflow: initial;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
  background-image: url(/style/images/spinner.gif);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 5rem 5rem;
}

/* Transparent Overlay */
#payment-spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  .accordion__container {
    width: calc(66.6666666667% - 4rem);
    float: left;
    margin-left: 2.4rem;
  }
}
.accordion__wrapper {
  margin-bottom: 2rem;
  opacity: 0.45;
}
.accordion--active {
  opacity: 1;
}
.accordion__content .order__section {
  display: none;
}
.accordion--active .accordion__content {
  padding: 1.8rem;
  border: 1px solid #423E39;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.accordion--active .accordion__content .order__section {
  display: block;
}
.accordion__content .addresses__wrapper {
  display: flex;
}
.accordion__header {
  padding: 1.8rem;
  width: 100%;
  background-color: #423E39;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;
  font-family: var(--font-alt);
}
@media (min-width: 992px) {
  .accordion__header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.accordion--active .accordion__header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media (min-width: 992px) {
  .accordion__header {
    font-size: 2.8rem;
  }
}
.accordion__header__step {
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1.6rem;
  margin-right: 2rem;
  color: black;
  border-radius: 50%;
}
.accordion__traverse__buttons .btn {
  width: 100%;
  display: block;
  margin-top: 1.8rem;
}
@media (min-width: 768px) {
  .accordion__traverse__buttons .form-group {
    display: flex;
    justify-content: flex-end;
  }
  .accordion__traverse__buttons .form-group .btn {
    margin-top: initial;
    width: auto;
    display: inline-block;
  }
  .accordion__traverse__buttons .form-group .btn:last-of-type {
    margin-left: 1.8rem;
  }
}

.card-details {
  padding: 4px 12px;
  background: #ECEBEB;
  height: 5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
}