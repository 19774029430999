.btn, input.btn {
  display: inline-block;
  padding: $sm $md;
  border-radius: $border_radius_global;
  border-style: solid;
  border-width: 0.1rem;
  font-family: $font_sans_1;
  font-weight: 500;
  text-align: center;
  font-size: $xsm;
  line-height: 1;
  text: {
    decoration: none;
  }
  @include transition();
  cursor:pointer;
  text-transform: uppercase;
  &--sm {
    padding: $xsm;
    font-size: $xs;
  }
  &--primary {
    //background-color: $color_btn_brand_primary_background;
    //color: $color_text_secondary;
    @include btn-theme($color_btn_brand_primary_background, $color_btn_brand_primary_text, $color_btn_brand_primary_hover_background, $color_btn_brand_primary_hover_text, $color_btn_brand_primary_background);
    &.checkout {
      @include btn-theme($color_btn_brand_primary_background, $color_btn_brand_primary_text, $color_btn_background_hover_positive, $_white);
    }
  }
  &--secondary {
    @include btn-theme(transparent, $_black, $_black, $_white);
  }
  &--subtle--white {
    @include btn-theme(transparent, $_white, $_white, $_grey_v_dark);
  }
  &--white {
    @include btn-theme(transparent, $_white, $_white, $_grey_v_dark);
  }
  &--dark {
    @include btn-theme($_black, $_white, rgba($_black, 0.5), $_white);
  }
  &--pos {
    @include btn-theme(transparent, $_black, $color_btn_background_hover_positive, $_white);
  }
  &--neg {
    @include btn-theme(transparent, $_black, $color_btn_background_hover_negative, $_white);
  }
  &--disabled, &[disabled] {
    @extend .btn--secondary;
    opacity: 0.25;
    pointer-events: none;
  }
}

input.btn {
  width: auto;
}

.dropdown-toggle {
  overflow: hidden;
}

.question {
  color: #fff;
  background-color: #c4c4c4;
  border-radius: 2rem;
  line-height: 1.4rem;
  font-size: 1.2rem;
  position: relative;
}