// A mixin that sets a block's background colour and text colour so there is less chance of clashing colours
@mixin backgroundAndColor($background, $color) {
  background-color: $background;
  color: $color;
}

// A quick utility class builder
@mixin color-modifiers($attr: 'color') {
  @each $name, $hex in $colors {
    &--#{$name} {
      #{$attr}: $hex;
      & .icon--fill {
        fill: $hex;
      }
    }
  }
}

// Cross Browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin advanced-color-modifiers() {
  @each $name, $color in $advanced-colors {
    &--#{$name} {
      background-color: map-get(map-get($advanced-colors,$name), 'bg');
      color: map-get(map-get($advanced-colors,$name), 'txt');
      & .icon--fill {
        fill: map-get(map-get($advanced-colors,$name), 'txt');
      }
    }
  }
}