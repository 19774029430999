@use "sass:math";
.icon {
  &__wrapper {
    width: $lg;
    height: $lg;
    display: flex;
    svg {
      display: block;
      margin: auto;
    }
  }
  &__input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    &--right {
      @extend .icon__input;
      right: 0;
    }
  }
  &__star {
    float: left;
    margin-right: math.div($xsm, 2);
    @include breakpoint($screen-lg) {
      margin-right: $smd;
    }
  }
}
