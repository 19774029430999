@use "sass:math";
.content__wrapper {
  @extend %kamblock;
}

.general-content {
  &__wrapper {
    @extend %kamblock;
    max-width: 88rem;
  }
  &__image {
    margin-bottom: $lg;
    @include breakpoint($screen-md) {
      margin-bottom: $xxl;
    }
  }
  &__button {
    text-align: center;
    margin-top: $lg;
    @include breakpoint($screen-md) {
      margin-top: $xxl;
    }
  }
}

.simple-content__wrapper {
  width: 100%;
  @include breakpoint($screen-sm) {
    @include grid-column(16);
  }
  @include breakpoint($screen-md) {
    @include grid-column(12);
  }
}

$reviewBlockPadding-xs: $xsm;
$reviewBlockPadding-sm: $sm;
$reviewBlockPadding-md: $md;
$reviewBlockPaddingBottom: $xxxl;

.review {
  &__wrapper {
    background-color: rgba($_red_dark, 0.9);
    color: $_white;
    text-align: center;
    font-family: $font_sans_2;
    font-family: var(--font-alt);
    font-weight: 300;
  }
  &__blocks {
    @extend %kamblock-flex;
    justify-content: space-between;
    flex-direction: column;
    @include breakpoint($screen-md) {
      flex-wrap: wrap;
      flex-direction: initial;
    }
  }
  &__block {
    width: 100%;
    padding: $reviewBlockPadding-xs;
    background-color: rgba($_white, 0.2);
    border-radius: $border_radius_global;
    padding-bottom: $reviewBlockPaddingBottom!important;
    position: relative;
    text-align: left;
    margin-bottom: $sm;
    &:first-child {
      width: 100%;
    }
    &:not(:first-child) {
      flex: 1;
    }
    @include breakpoint($screen-sm) {
      padding: $reviewBlockPadding-sm;
    }
    @include breakpoint($screen-md) {
      margin-bottom: 0;
      @include grid-column(8);
      padding: $reviewBlockPadding-md;
    }
  }
  &__title {
    width: 100%;
    font-family: $font_sans_1;
  }
  &__stars__wrapper {
    @include clearfix;
    margin-bottom: 2rem;
  }
  &__name, &__g {
    position: absolute;
    bottom: math.div($reviewBlockPaddingBottom, 4);
  }
  &__name {
    font-weight: 500;
  }
  &__g {
    right: $reviewBlockPadding-xs;
    @include breakpoint($screen-sm) {
      right: $reviewBlockPadding-sm;
    }
    @include breakpoint($screen-md) {
      right: $reviewBlockPadding-md;
    }
  }
}

.contact {
  &__wrapper {
    @extend %kamblock;
    @include breakpoint($screen-md) {
      display: flex;
      height: inherit;
      &:last-of-type > div {
        margin-bottom: 0;
      }
    }
  }
  &__left, &__right, &__telephone, &__address {
    width: 100%;
    @include breakpoint($screen-md) {
      width: 50%;
    }
  }
  &__left {
    padding: 0 $sm;
    @include breakpoint($screen-md) {
      padding: 0 $md 0 $sm;
    }
  }
  &__details {
    @include breakpoint($screen-md) {
      display: flex;
    }
  }
  &__overview, &__telephone, &__address, &__hours {
    margin-bottom: $lg;
    @include breakpoint($screen-md) {
      margin-bottom: $xl;
    }
  }
}

.design {
  &__portfolio {
    &__btn-wrapper {
      width: 100%;
      .btn {
        width: 100%;
        @include breakpoint($screen-sm) {
          width: auto;
        }
      }
      &:last-of-type {
        margin-top: $sm;
      }
      @include breakpoint($screen-sm) {
        @include grid-column(12, $gutterless-grid);
        display: flex;
        &:last-of-type {
          justify-content: flex-end;
          margin-top: 0;
        }
      }
    }
  }
}

.project {
  &-grid {
    display: block;
    @include breakpoint($screen-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    &-item {
      &__wrapper {
        width: 100%;
        margin-bottom: $sm;
        display: block;
        @include breakpoint($screen-sm) {
          //display: flex;
          width: calc(50% - #{$sm});
          + .project-grid-item__wrapper {
            &:nth-of-type(even) {
              margin-left: $sm;
            }
          }
        }
      }
      &__info {
        padding: $lg;
        @include breakpoint($screen-sm) {
          padding: $xxl;
        }
      }
    }
  }
}

.studio {
  &__overview, &__images {
    &__wrapper {
      width: 100%;
    }
  }
  &__overview {
    &__wrapper {
      @include breakpoint($screen-md) {
        @include grid-column(10);
      }
    }
  }
  &__images {
    &__wrapper {
      @include breakpoint($screen-md) {
        @include grid-column(14);
      }
    }
  }
}

.boxed-content {
  &__wrapper {
    display: flex;
    flex-flow: column;
    max-width: 1366px;
    margin: 0 auto;
    @include breakpoint($screen-md) {
      padding: $lg + $xxxs;
      border: $border_style_global;
      border-radius: $border_radius_global;
    }
  }
  &__row {
    @include breakpoint($screen-md) {
      display: flex;
      flex-wrap: wrap;
    }
    form {

    }
  }
  &__form {
    &__row {
      @include grid-column(24, $gutterless-grid);
      padding-bottom: $smd;
      @include breakpoint($screen-md) {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        justify-content: space-between;
        padding: $sm 0;
      }
      .control-label {
        padding-bottom: $xsm;
        padding-right: $xsm;
        display: block;
        @include breakpoint($screen-md) {
          @include grid-column(6, $gutterless-grid);
        }
        small {
          display: block;
          font-size: $xs;
          padding-top: $xsm;
        }
      }
      .input__wrapper {
        @include breakpoint($screen-md) {
          @include grid-column(18, $gutterless-grid);
        }
      }
    }
  }
}