// Color scheme
// An underscore before variable name ($_var) means it is only to be used within the variables.scss file DO NOT use these variables in any other file. Use the $context_location_specific_variable instead.

$_white: #FFFFFF; // White //
$_black: #000000; // Dark //
$_grey_v_dark: #2E2E2E;
$_grey_dark: #413E3A;
$_grey: #423E39;
$_grey_light: #585858;
$_grey_v_light: #707070;
$_grey_vv_light: #ECEBEB;
$_blue_dark: #375773;
$_red_dark: #5C322D;
$_red_light: #5C322D;
$_red: #E41E1D;
$_green: #41a14b;

$_color_hover_shift: 8%;

// Global Styles
$global_color_shift: 5%;

/// Brand Colours
$color_brand_primary: $_blue_dark;
$color_brand_secondary: $_grey;

$colors: (
        primary: $color_brand_primary,
        secondary: $color_brand_secondary,
        negative: $_white,
        positive: $_black
);

$advanced-colors: (
        primary: (
                bg: $color_brand_primary,
                txt: $color_brand_secondary,
                solid-hover-bg: lighten($color_brand_primary, $_color_hover_shift)
        ),
        secondary: (
                bg: $color_brand_secondary,
                txt: $color_brand_secondary,
                solid-hover-bg: lighten($color_brand_secondary, $_color_hover_shift)
        ),
        negative: (
                bg: $_white,
                txt: $color_brand_secondary,
                solid-hover-bg: lighten($_white, $_color_hover_shift)
        ),
        positive: (
                bg: $_black,
                txt: $color_brand_secondary,
                solid-hover-bg: lighten($_black, $_color_hover_shift)
        )
);

// Core Colours
$color_body_background: $_white;
$color_main_background: $_white;

$color_fill_dark: $_black;

// Border color consistency
$color_border_global: $_grey;

// Border style consistency
$border_style_global: 1px solid $color_border_global;

// Border Radius Consistency
$border_radius_global: 4px;

// Type Colours
$color_text_main: $_grey_dark;
$color_text_secondary: $_white;
$color_banner_background: $_red_dark;

// Navigation Colours
$color_navigation_background: $_white;
$color_navigation_background_mobile: $color_navigation_background;
$color_navigation_link: $_black;
$color_navigation_link_hover_background: $_white;
$color_submenu_navigation_link: $color_navigation_link;
$color_submenu_navigation_link_border: $_grey;
$color_submenu_navigation_link_hover: $_black;
$color_submenu_navigation_link_hover_background: $_white;
$color_mobile_navigation_control: $_grey_dark;
$color_mobile_navigation_control_background: $_grey;
$color_mobile_navigation_control_active: $_white;
$color_mobile_navigation_control_background_active: $_black;
$color_mobile_navigation_control_hover: $color_brand_primary;
$color_mobile_menu_navigation_link_border: $_grey_vv_light;
$color_account_navigation_item_background_hover: $_grey_vv_light;

$color_navigation_dash_background: $color_brand_primary;

$color_supernav_background: $_white;
$color_supernav_link_background: $_white;
$color_supernav_link_hover_background: $_white;
$color_supernav_link_text: $_grey_dark;
$color_supernav_link_hover_text: $_grey_dark;


// Mega Menu Colours

$color_megamenu_background: $_grey_vv_light;
$color_megamenu_submenu_link_background: $_grey_vv_light;
$color_megamenu_submenu_background: $color_megamenu_background;
$color_megamenu_submenu2_background: $color_megamenu_background;
$color_megamenu_submenu2_link_background: $color_megamenu_background;
$color_megamenu_submenu_link_background_hover:  $_white;
$color_megamenu_submenu2_link_background_hover: $_white;
$color_megamenu_submenu_border: $_grey_v_light;

// Footer Colours
$color_footer_background: $_grey;
$color_footer_link: $_white;
$color_footer_link_hover_background: $_white;
$color_footer_link_hover: $_grey;
$color_footer_link_arrow: $_black;
$color_footer_link_arrow_hover:$_black;
$color_footer_content: $_white;
$color_footer_legal_background: $color_footer_background;
$color_footer_legal_content: $color_footer_content;
$color_footer_legal_link: $color_footer_content;
$color_footer_legal_link_hover: $_grey;
$color_footer_legal_link_hover_background: $_white;

// Pagination Colours
$color_pagination_item: $_black;
$color_pagination_item_hover: $_white;
$color_pagination_item_background: $_grey_v_light;
$color_pagination_item_hover_background: $_grey_light;
$color_pagination_item_active: $_white;
$color_pagination_item_active_background: $color_brand_primary;

// Body Copy Link Colours
$color_link_main: $color_brand_primary;
$color_link_background: $_white;
$color_link_visited: darken($color_brand_primary, 10%); // A darker blue for now //

// Form Field Colours - to do
$color_form_placeholder: $_grey;
$color_form_input_background: $_white;
$color_form_input: $_black;
$color_form_input_border: $_black;
$color_form_input_hover_background: $_grey_light;
$color_required: $_red;
$color_form_range_background: $_grey_v_dark;
$color_form_range_text: $_white;

// Blockquote Colours - to do
$color_blockquote_main: $_black;
$color_blockquote_background: $_grey_light;


// Section Background Colours and text colours for themes - to do
$color_section_darkest_background: $_black;
$color_section_darkest_background_text: $_white;

$color_section_lightest_background: $_white;
$color_section_lightest_background_text: $_black;

$color_section_light_background: $_grey_vv_light;
$color_section_light_background_text: $_black;

$color_section_medium_background: $_grey;
$color_section_medium_background_text: $_black;

$color_section_brand-primary_background: $_red;
$color_section_brand-primary_background_text: $_white;

$color_promo_stripe_background: $color_section_darkest_background;
$color_promo_stripe: $_white;

//Standout Box - to do
$color_standout_box_background: $color_section_lightest_background;

// Testimonial Block - to do
$color_testimonial_background: $color_section_light_background;
$color_testimonial_text: $color_section_light_background_text;
$color_testimonial_avatar_background: $_white;

// Social Icons Colors
$color_social_icon: $_white;

// Message Colours
$color_error: $_red;
$color_error_background: $_grey_light;
$color_success: $_green;
$color_success_background: $_grey_light;

// Button Colours - to do
$color_btn_background_positive: $_green;
$color_btn_text_positive: $_white;
$color_btn_background_negative: $_red;
$color_btn_text_negative: $_white;
$color_btn_background_hover_positive: $color_btn_background_positive;
$color_btn_text_hover_positive: $_white;
$color_btn_background_hover_negative: $color_btn_background_negative;
$color_btn_text_hover_negative: $_white;
$color_btn_brand_primary_background: $color_brand_primary;
$color_btn_brand_primary_text: $_white;
$color_btn_brand_primary_hover_background: darken($color_btn_brand_primary_background, $global_color_shift);
$color_btn_brand_primary_hover_text: $_white;
$color_btn_subtle_background: $_white;
//$color_btn_subtle_background: $_darker_grey;
$color_btn_subtle_text: $_white;
$color_btn_subtle_hover_background: darken($color_btn_subtle_background, $global_color_shift);
$color_btn_subtle_hover_text: $_white;

// Card Colours

// Market Cards - to do
$color_card_market_heading: $_black;
$color_card_market_border_inactive: $_grey_light;
$color_card_market_border_active: $_grey;

%dark-background {
        @include backgroundAndColor($color_section_darkest_background, $color_section_darkest_background_text);
}

%medium-background {
        @include backgroundAndColor($color_section_medium_background, $color_section_medium_background_text);
}

%light-background {
        @include backgroundAndColor($color_section_light_background, $color_section_light_background_text);
}

%lightest-background {
        @include backgroundAndColor($color_section_lightest_background, $color_section_lightest_background_text);
}

%brand-primary-background {
        @include backgroundAndColor($color_section_brand-primary_background, $color_section_brand-primary_background_text);
}
