@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flexbox {
  @include clearfix;
  display: flex; // new
  align-items: stretch;
  flex-wrap: wrap;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -ms-flex:  $values;
  -webkit-flex:  $values;
  flex:  $values;
}