@mixin chevron($direction, $size: 1.2rem, $thickness: 0.2rem, $color: inherit) {
  $d: $direction;
  position: absolute;
  width: $size;
  height: $size;
  pointer-events: none;
  border: $thickness solid;
  border-bottom: none;
  border-left: none;
  color: $color;
  @if $d == up {
    transform: rotate(315deg);
  }
  @else if $d == right {
    transform: rotate(45deg);
  }
  @else if $d == down {
    transform: rotate(135deg);
  }
  @else if $d == left {
    transform: rotate(225deg);
  } @else {
    @error "unknown direction '#{$direction}'. Chevron mixin accepts: up, right, down, left"
  }
  transition: transform 0.15s ease-in-out;
}