// Footer
.footer {
  background: $color_footer_background;
  @include grid-column(24, $gutterless-grid);
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  h2 {
    font-family: $font_sans_1;
    font-family: var(--font-main);
  }
  &__wrapper {
    max-width: $site-max-width;
    margin: 0 auto;
    padding: 2rem 2rem;
    //@include breakpoint($screen-md) {
    //  padding: 4rem 0;
    //}
  }
  &__links {
    border-top: 0.06rem solid $color_footer_content;
    border-bottom: 0.06rem solid $color_footer_content;
    //padding: $xs 0 $xs;
    color: $color_footer_content;
    margin-bottom: $lg;
    &__row {
      display: flex;
      flex-direction: column;
      li {
        //margin: 0 auto;
        text-align: center;
      }
    }
    @include breakpoint($screen-sm) {
      &__row {
        flex-flow: wrap;
        flex-direction: row;
        justify-content: space-between;
       }
     }
    p, a {
      color: $color_footer_link;
      font-size: $xsm;
    }
    a {
      text-decoration: none;
      padding: $xsm;
      display: block;
      @include breakpoint($screen-sm) {
        padding: $md $sm;
      }
      &:hover {
        color: $color_footer_link_hover;
        background-color: $color_footer_link_hover_background;
      }
      &:focus {
        color: $color_footer_link_hover;
        background-color: $color_footer_link_hover_background;
        //border-radius: 0.2rem;
        padding-left: $xxs;
        outline: none;
      }
    }
    &__social {
      margin-bottom: $lg;
      ul {
        justify-content: center;
        &.footer__links__row {
          flex-direction: row;
        }
        li {
          padding: $xs;
          a {
            display: block;
            width: 3rem;
            height: 3rem;
            transition: opacity 0.25s ease-in-out;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__heading {
      //@extend h3;
      font-size: 1.4rem;
      margin-bottom: $sm;
      font-weight: 500;
    }
  }
  &__newsletter {
    text-align: center;
    &__text {
      color: $color_footer_link;
      max-width: 67rem;
      margin: 0 auto;
      h2 {
        margin-bottom: 2rem;
      }
      p {
        font-size: $sm;
        @include breakpoint($screen-md) {
          font-size: $md;
        }
        line-height: 1.25;
      }
    }
    &__form {
      color: $color_footer_link;
    }
  }
  &__legal {
    @include clearfix;
    text-align: center;
    background-color: $color_footer_legal_background;
    color: $color_footer_legal_content;
    padding: 0 0 $sm 0;
    &__copyright {
      display: inline-flex;
      margin-bottom: $smd;
      font-size: $sm;
      pre {
        color: $color_footer_link;
      }
    }
    &__registration {
      margin-bottom: $sm;
      p {
        font-size: $xs;
      }
    }
  }
}