// Fonts
// We use $font_use-case for specificity
// We use appended naming to keep things clear and descriptive


$fontpath: "/style/fonts";

@include font-face("ComfortaaVariable", "#{$fontpath}/comfortaa/Comfortaa-VariableFont_wght", 100 900, normal, ttf);

@include font-face("SofiaProWeb", "#{$fontpath}/SofiaPro/SofiaProLight-webfont", 300, normal, woff woff2 );
@include font-face("SofiaProWeb", "#{$fontpath}/SofiaPro/SofiaProRegular-webfont", 400, normal, woff woff2 );
@include font-face("SofiaProWeb", "#{$fontpath}/SofiaPro/SofiaProMedium-webfont", 500, normal, woff woff2 );
@include font-face("SofiaProWeb", "#{$fontpath}/SofiaPro/SofiaProBold-webfont", 600, normal, woff woff2 );



$font_sans_1: 'ComfortaaVariable', Helvetica, Arial, sans-serif;
$font_sans_2: 'SofiaProWeb', Helvetica, Arial, sans-serif;

:root {
  --font-main: #{$font_sans_1};
  --font-alt: #{$font_sans_2};
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  line-height: 1.25;
  font-size: $sm;
}


p, address {
  line-height: 1.5;
  margin-bottom: 1em;
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  font-weight: 300;
  //@include breakpoint($screen-md) {
    font-size: $sm;
  //}
  .contact__wrapper div & {
    font-size: $xsm;
    margin-bottom: 0;
    &:last-of-type {
      margin-bottom: 1em;
    }
  }
  //.page-intro & {
  //  font-size: $sm + ($xxs / 2);
  //}
  //blockquote & {
  //  font-size:  $sm + ($xxs / 2);
  //  font-style: normal!important;
  //}
  //.cb__simple__text & {
  //  font-weight: 100;
  //  margin: auto;
  //    font-size: $smd;
  //    max-width: 50ch;
  //  @include breakpoint($screen-md) {
  //    font-size: calc(1.71875vw + 1px);
  //    max-width: 50ch;
  //  }
  //}
  //.wysiwyg & {
  //  margin-bottom: $sm;
  //}
}

//blockquote {
//  .wysiwyg & {
//    opacity: 0.6;
//    //margin-left: $smd;
//    font-family: var(--font-serif);
//    padding: $smd 0 $smd $smd;
//    cite {
//      font-size: $xsm;
//      color: $color_brand_secondary;
//      display: inline-block;
//      width: 100%;
//      text-align: right;
//      padding-top: $mdl;
//      &:before {
//        content:'— ';
//      }
//    }
//    p {
//      margin-bottom: 0;
//    }
//    &.is-style-large {
//      padding: $lg $smd;
//      cite {
//        font-size: $xsm;
//      }
//    }
//  }
//}

a {
  text-decoration: none;
  //color: $color_link_main;
  color: inherit;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  p > &, p span &, label &, &.link__footer, h1 &, h2 &, h3 &, h4 &, h5 &, .h1 &, .h2 &, .h3 &, .h4 &, .h5 & {
    color: $color_text_main;
    text-decoration: none;
    padding: 0 0.4rem;
    display: inline;
    position: relative;
    font-weight: normal;
    z-index: 1;
    box-shadow: inset 0rem -0.2rem $color_link_background;
    @include transition(box-shadow);
  }
}

.link__back {
  font-family: var(--font-alt);
  color: $color_brand_primary;
  display: inline-block;
  margin-bottom: $xl;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4, h5, .h5 {
  font-weight: inherit;
  line-height: 1.25;
  text-transform: uppercase;
  font-family: $font_sans_1;
  font-family: var(--font-main);
}

h2, h3, h4, .h2, .h3, .h4, h5, .h5 {
  margin-bottom: 1em;
}

h1, .h1 {
  font-size: $smd;
  @include breakpoint($screen-md) {
    font-size: 5rem;
  }
  & span {
    display: block;
  }
}

h2, .h2 {
  font-size: $md;
  @include breakpoint($screen-md) {
    font-size: $lg;
  }
  font-weight: 400;
  &--small {
    @extend .h3;
  }
}

h3, .h3 {
  font-size: $smd;
  font-weight: 400;
  @include breakpoint($screen-md) {
    font-size: $md;
  }
}
//
//h4, .h4 {
//  font-size: $sm;
//  font-weight: 400;
//}
//
//h5 {
//  font-size: $xsm;
//  font-weight: 600;
//}

ul {
  font-family: $font_sans_2;
  font-family: var(--font-alt);
  .content-block & {
    font-weight: 300;
    line-height: 1.5;
  }
}

.wysiwyg {
  img + & {
    margin-top: $sm;
    display: block;
  }
  h2, .h2 {
    font-size: $mdl;
    font-weight: 400;
  }

  h3, .h3 {
    font-weight: 200;
    font-size: $md;

  }

  h4, .h4 {
    font-size: $smd;
    font-weight: 200;
  }

  h5 {
    font-size: $sm;
    font-weight: 600;
  }

  h6 {
    font-size: $xsm;
    font-weight: 600;
  }
}

small, .font_small, small p, .small p {
  font-size: $xsm;
  .h1 &, h1 & {
    font-size: 0.35em;
    padding-bottom: $lg;
    display: block;
    letter-spacing: 0.01em;
  }
}

sub, sup {
  /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
  font-size: 75%;

  /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
  line-height: 0;

  /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
  position: relative;

  /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
  vertical-align: baseline;
}

sup {
  /* Move the superscripted text up */
  top: -0.5em;
}

sub {
  /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
  bottom: -0.25em;
}

ul, .ul, ol, .ol {
  .wysiwyg &, &--basic {
    line-height: 1.6;
  }
  &.unstyled {
    list-style: none;
  }
}

ul {
  .wysiwyg & {
    li {
      position: relative;
      margin-left: calc( #{$sm} + 0.4rem );
    }
  }
}

ol {
  list-style-type: none;
  counter-reset: item;
  & > li {
    display: table;
    counter-increment: item;
    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;
    }
  }
}

li ol > li {
  &:before {
    content: counters(item, ".") " ";
  }
}