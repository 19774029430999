@use "sass:math";

.account {
  &-container {
    @include grid-container()

  }
  // THE SIDEBAR NAVIGATION
  &__sidebar {
    &__wrapper {
      margin-bottom: $md;
      @include grid-column(24, $gutterless-grid);
      @include breakpoint($screen-md) {
        @include grid-column(4);
      }
      .list--active{
        display: block;
        li a.selected{
          background-color: $_grey_vv_light;
        }
      }
    }
  }
  &__menu__trigger {
    display: block;
    @include breakpoint($screen-md) {
      display: none;
    }
  }
  &__side__menu {
    margin: $sm auto;
    font-size: $xsm;

    font-family: $font_sans_1;
    font-family: var(--font-main);
    @include breakpoint($screen-md) {
      margin: auto;
    }
    @include breakpoint(0 $screen-md) {
      display: none;
    }
    border-top: $border_style_global;
    border-color: rgba($_grey_dark, 0.2);

    li ul {
      display: none;
      a {
        padding-left: $lg;
        //text-transform: none;
      }
    }
    li {
      position: relative;
      overflow: hidden;
      &:first-of-type {
        a {
        }
      }
      a {
        display: block;
        position: relative;
        padding: $sm;
        text-transform: uppercase;
        font-family: $font_sans_1;
        font-family: var(--font-main);
        border-bottom: $border_style_global;
        border-color: rgba($_grey_dark, 0.2);
        span {
          position: relative;
          z-index: 2;
        }
        &:before {
          content: '';
          position: absolute;
          //width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 100%;
          background-color: $_grey_vv_light;
          z-index: 1;
          transition: right 0.25s ease-in-out;
        }
        &:hover {
          &:before {
            right: 0;
          }
        }
      }
    }
  }
  // THE MAIN CONTENT AREA
  &__content {
    &__wrapper {
      .section--padx2{
        padding-top: 0;
        padding-bottom: 0;
      }
      @include grid-column(24, $gutterless-grid);
      @include breakpoint($screen-md) {
        @include grid-column(20);
      }

      @include breakpoint($screen-lg) {
        @include grid-column(18);
        @include grid-push(2);
      }
      // ACCOUNT HOME IMAGE GRID
      .grid__block {
        @extend .grid__block;
        @include breakpoint($screen-md) {
          @include grid-column(11);
        }
        @include breakpoint($screen-xsm $screen-md) {
          @include grid-column(12);
        }
        @include breakpoint(0 $screen-xsm) {
          @include grid-column(24);
        }
      }
    }
    &__heading {
      font-size: $xs;
      text-transform: uppercase;
      @include breakpoint($screen-md) {
        @include grid-column(24);
        display: block;
      }
    }
    &__intro {
      max-width: 65ch;
      padding-bottom: $md;
      p{
        min-height: 5.4rem;
      }
      @include breakpoint($screen-md) {
        margin-left: 2.4rem;
      }
    }
  }
  // NEW ORDER
  &__artwork {
    &__lists {
      &__wrapper {
        display: flex;
      }
    }
    &__column {
      margin-bottom: $lg;
      @include grid-column(24, $gutterless-grid);
      @include breakpoint($screen-md) {
        margin-bottom: $xxl;
        &:first-of-type {
          padding-right: $smd;
        }
        @include grid-column(12, $gutterless-grid);
      }
      .artwork {
        &__wrapper {
          margin-bottom: $md;
        }
        &__intro {
          &__wrapper {
            margin-bottom: $md;
            @include grid-container();
            @include breakpoint($screen-md) {
              display: flex;
              align-items: flex-end;
              padding-right: $xsm;
            }
          }
          @include grid-column(24, $gutterless-grid);
          @include breakpoint($screen-md) {
            @include grid-column(18, $gutterless-grid);
          }
        }
        &-toggle {
          &__wrapper {
            display: flex;
            flex-flow: row;
            border: $border_style_global;
            border-radius: $border_radius_global;
            max-width: $xl * 2;
          }
          &__icon__wrapper {
            display: flex;
            padding: math.div($xxxs, 2);
            &:first-of-type {
              border-top-left-radius: $border_radius_global;
              border-bottom-left-radius: $border_radius_global;
              border-right: $border_style_global;
            }
            &:last-of-type {
              border-top-right-radius: $border_radius_global;
              border-bottom-right-radius: $border_radius_global;
            }
            &.is--active {
              background-color: $_grey_vv_light;
            }
            svg {
              width: 100%;
              height: 100%;
              max-width: $xl;
              max-height: $mdl;
            }
          }
        }
        &__image__wrapper {
          display: none;
          img {
            display: none;
          }
        }
        &__image__wrapper.image--active {
          display: block;
          border: $border_style_global;
          border-color: rgba($_grey_light, 0.2);
          margin-bottom: $xsm;
          img {
            display: block;
          }
        }
        &__name {
          font-family: $font_sans_2;
          font-family: var(--font-alt);
        }
      }
    }
  }
  // ORDER HISTORY
  &-orders {
    &__col {
      @include grid-column(6, $gutterless-grid);
      margin-bottom: $smd;
      font-size: math.div($mdl, 2);
      @include breakpoint($screen-md) {
        font-size: $xsm;
        @include grid-column(3);
      }
      &__desc {
        @extend .account-orders__col;
        @include grid-column(18, $gutterless-grid);
        @include breakpoint(max-width $screen-md) {
          margin-left: calc(25%);
        }
        @include breakpoint($screen-md) {
          @include grid-column(12);
        }
      }
      &__link {
        @extend .account-orders__col;
        @include breakpoint(max-width $screen-md) {
          margin-left: calc(25%);
        }
      }
      &-heads__wrapper {
        @include grid-container();
      }
      &-head {
        font-size: $xs;
        margin-bottom: $sm;
        text-transform: uppercase;
        font-weight: bold;
        @include breakpoint($screen-md) {
          font-weight: 800;
        }
        &.account-orders__col__desc {

          @include breakpoint(max-width $screen-md) {
            display: none;
          }
        }
      }
    }
    &__item {
      @include grid-container();
      margin-bottom: $mdl;
    }
    &__link {
      color: $color_brand_primary;
      font-family: $font_sans_2;
      font-family: var(--font-alt);
      text-decoration: underline;
    }
  }
  &-user {
    &__details {
      &-form {
        &__wrapper {
          @include grid-column(24, $gutterless-grid);
          @include breakpoint($screen-md) {
            @include grid-column(12, $gutterless-grid);
          }
        }
        @include grid-column(24, $gutterless-grid);
        @include breakpoint($screen-md) {
          @include grid-column(24);
        }
      }
    }
  }
}

// ARTWORK LIBRARY SPECIFIC

.artwork {
  &-library {
    &__headings {
      margin-bottom: $sm;
      @include grid-container();
    }
    &__col {
      @include grid-column(24, $gutterless-grid);
      margin-bottom: $xs;
      @include breakpoint($screen-md) {
        margin-bottom: initial;
        @include grid-column(3);
        display: flex;
        align-items: center;
      }
      &--name {
        @extend .artwork-library__col;
        @include breakpoint($screen-md) {
          @include grid-column(4);
        }
      }
      &--date {
        @extend .artwork-library__col;
        @include breakpoint(max-width $screen-md) {
          @include grid-column(12, $gutterless-grid);
          padding-right: 2.4rem;
        }
      }
      &--type {
        @extend .artwork-library__col;
        @include breakpoint(max-width $screen-md) {
          @include grid-column(12, $gutterless-grid);
        }
      }
      &--action {
        @extend .artwork-library__col;
        //@include breakpoint($screen-md) {
        //  @include grid-column(6);
        //}
      }
    }
    &__items__wrapper {
      @include grid-container();
    }
    &__item {
      &__wrapper {
        @include breakpoint($screen-md) {
          display: flex;
        }
        @include grid-container();
        margin-bottom: $sm;
        .btn {
          display: flex;
          width: 100%;
          justify-content: center;
          @include breakpoint(max-width $screen-md) {
            //margin-bottom: $xxs;
          }
        }
      }
    }
    &__mini-heading {
      display: block;
      font-size: $xxs;
      text-transform: uppercase;
      margin-bottom: $xxs;
      @include breakpoint($screen-md) {
        display: none;
      }
    }
  }
}